import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';
import { FormattedMessage } from '../../util/reactIntl';

import ArbitrationAgreementEN from './files/Umoozi-Arbitration-Agreement-English.pdf';
import ArbitrationAgreementFR from './files/Umoozi-Arbitration-Agreement-French.pdf';
import ArbitrationAgreementES from './files/Umoozi-Arbitration-Agreement-Spanish.pdf';

const arbitrationAgreement = {
  'en': {
    title: 'UVR-Arbitration-Agreement-English',
    file: ArbitrationAgreementEN,
  },
  'fr': {
    title: 'UVR-Arbitration-Agreement-French',
    file: ArbitrationAgreementFR,
  },
  'es': {
    title: 'UVR-Arbitration-Agreement-Spanish',
    file: ArbitrationAgreementES,
  },
}

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  const language = useSelector((state) => state.userSiteSettings.userLanguage);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        <FormattedMessage id="TermsOfServicePage.part1" />
      </p>
      <p>
        <FormattedMessage id="TermsOfServicePage.part2" />
      </p>
      <p>
        <FormattedMessage id="TermsOfServicePage.part3" />
      </p>
      <p>
        <FormattedMessage id="TermsOfServicePage.part4" />
      </p>
      <p>
        <FormattedMessage id="TermsOfServicePage.part5" />
      </p>
      <p>
        <FormattedMessage id="TermsOfServicePage.part6" />
      </p>
      <h2><FormattedMessage id="TermsOfServicePage.downloadLinksTitle" /></h2>
      <p className={css.downloadLinksContainer}>
        {arbitrationAgreement[language] && (
          <a href={arbitrationAgreement[language].file} download={arbitrationAgreement[language].title} target='_blank'>
            {arbitrationAgreement[language].title}.pdf
          </a>
        )}
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
