import React from 'react';
import classNames from 'classnames';

import css from './IconHeartOutline.module.css';

interface Props {
  rootClassName?: string;
  className?: string;
}

const IconHeartOutline = ({
  rootClassName = '',
  className = ''
}: Props) => {
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      width="70"
      height="64"
      viewBox="0 0 70 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M38.2154 4.86359C41.3355 3.33639 44.0572 2.83337 48.2796 2.83342C59.085 2.87817 67.0827 11.9913 67.0827 23.5997C67.0827 32.4601 62.1423 41.0192 52.9402 49.2956C48.1101 53.6398 41.943 57.9389 37.5263 60.226L34.9994 61.5346L32.4724 60.226C28.0557 57.9389 21.8886 53.6398 17.0585 49.2956C7.85636 41.0192 2.91602 32.4601 2.91602 23.5997C2.91602 11.8671 10.839 2.83337 21.7418 2.83337C25.8127 2.83337 28.674 3.38397 31.8546 4.9571C32.962 5.5048 34.0024 6.15447 34.9701 6.90434C35.9766 6.10993 37.0603 5.429 38.2154 4.86359Z" stroke-width="4"/>
    </svg>
  )
};

export default IconHeartOutline;
