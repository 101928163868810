import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaPhone,
  IconSocialMediaMail,
  IconLocation,
  Logo,
  ExternalLink,
  NamedLink,
} from '..';

import css from './Footer.module.css';

interface FooterProps {
  rootClassName?: string;
  className?: string;
}

const renderSocialMediaLinks = (intl: IntlShape) => {
  const { siteFacebookPage, sitePhoneTo, siteMailto } = config;

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToPhone = intl.formatMessage({ id: 'Footer.goToPhone' });
  const goToMail = intl.formatMessage({ id: 'Footer.goToMail' });

  const mailLink = siteMailto ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteMailto}
      className={css.icon}
      title={goToMail}
    >
      <IconSocialMediaMail />
    </ExternalLink>
  ) : null;

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const phoneLink = sitePhoneTo ? (
    <ExternalLink
      key="linkToInstagram"
      href={sitePhoneTo}
      className={css.icon}
      title={goToPhone}
    >
      <IconSocialMediaPhone />
    </ExternalLink>
  ) : null;
  return [mailLink, fbLink, phoneLink].filter(v => v != null);
};

const Footer: React.FC<FooterProps> = ({ rootClassName, className}) => {
  const intl = useIntl();
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <NamedLink name="LandingPage" className={css.addressLink}>
                  <div className={css.addressContainer}>
                    <IconLocation />
                    <FormattedMessage id="Footer.address" />
                  </div>
                </NamedLink>
              </div>
            </div>

            <div className={css.infoLinksContainer}>
              <div className={css.infoLinksWrapper}>
                <div className={css.infoLinks}>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <NamedLink name="AboutPage" className={css.aboutUs}>
                        <FormattedMessage id="Footer.toAboutPage" />
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="ContactUsPage" className={css.link}>
                        <FormattedMessage id="Footer.toContactPage" />
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="CareersPage" className={css.link}>
                        <FormattedMessage id="Footer.toCareersPage" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>

                <div className={css.infoLinks}>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <NamedLink name="FaqPage" className={css.link}>
                        <FormattedMessage id="Footer.toFAQPage" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>

                <ul className={css.tosAndPrivacyMobile}>
                  <li className={css.legalLinkItem}>
                    <NamedLink name="TermsOfServicePage" className={css.legalLinkMobile}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li className={css.legalLinkItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLinkMobile}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.someLinks}>{socialMediaLinks}</div>
            </div>

            <div className={css.extraLinks}>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li className={css.legalLinkItem}>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li className={css.legalLinkItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
                <NamedLink name="LandingPage" className={css.copyrightLink}>
                  <FormattedMessage id="Footer.copyright" />
                </NamedLink>
              </div>
            </div>
          </div>
          <div className={css.someLinksMobile}>{socialMediaLinks}</div>

          <div className={css.copyrightAnnAddressMobile}>
            <NamedLink name="LandingPage" className={css.addressLink}>
              <div className={css.addressContainerMobile}>
                <IconLocation />
                <FormattedMessage id="Footer.address" />
              </div>
            </NamedLink>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
