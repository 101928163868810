import React from 'react';
import { getEachDateOfBooking } from '../../util/dates';
import { getTotalPriceForAllBookedDays } from '../../util/price';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl, discount } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  const { Money } = sdkTypes;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const datesBooked = getEachDateOfBooking(
    transaction.booking.attributes.start,
    transaction.booking.attributes.end
  );

  // Cannot rewrite readonly property 'lineTotal' so create another object with desirable 'lineTotal'
  const unitPurchaseWithDiscount = {
    ...unitPurchase,
    lineTotal: new Money(
      getTotalPriceForAllBookedDays(datesBooked, unitPurchase.unitPrice.amount, discount),
      config.currency
    ),
  };

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;

  const unitPrice = unitPurchase
    ? formatMoney(
        intl,
        new Money(unitPurchaseWithDiscount.lineTotal.amount / datesBooked.length, config.currency)
      )
    : null;

  const total = unitPurchase ? formatMoney(intl, unitPurchaseWithDiscount.lineTotal) : null;

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
