import React from 'react';
import DiscountImage from './discount-label.svg';

import css from './DiscountIndicator.module.css';

const DiscountIndicator = () => {
  return <img className={css.discountImage} src={DiscountImage} alt="discount" />;
};

export default DiscountIndicator;
