import { actions } from './reducer';
import { denormalisedEntities } from '../../../util/data';
import { storableError } from '../../../util/errors';
import { parse } from '../../../util/urlHelpers';
import { actions as editListingPageActions } from "../editListingPage/reducer";

const RESULT_PAGE_SIZE = 9;

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.manageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(actions.fetchListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(actions.addOwnEntities(response));
      dispatch(actions.fetchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(actions.fetchListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(actions.closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(actions.closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(actions.closeListingError(storableError(e)));
    });
};

export const discardDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(actions.closeListingRequest(listingId));

  return sdk.ownListings
    .discardDraft({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(actions.closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(actions.closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(actions.openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(actions.openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(actions.openListingError(storableError(e)));
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export const loadNextListingsPage = page => (dispatch, getState, sdk) => {
  return sdk.ownListings
    .query({
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      include: ['images'],
      'limit.images': 1,
      page,
      per_page: RESULT_PAGE_SIZE,
    })
    .then(response => {
      dispatch(actions.addNextEntities(response));
      return response;
    })
    .catch(e => {
      dispatch(actions.fetchListingsError(storableError(e)));
      throw e;
    });

};

export function requestUpdateListing(data) {
  return (dispatch, getState, sdk) => {
    dispatch(editListingPageActions.updateListingRequest());

    return sdk.ownListings
      .update(data)
      .then(() => {
        sdk.ownListings
          .query({
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
            include: ['images'],
            'limit.images': 1,
            page: 1,
            per_page: RESULT_PAGE_SIZE,
          })
          .then(response => {
            dispatch(actions.addOwnEntities(response));
            dispatch(actions.fetchListingsSuccess(response));
            return response;
          })
          .catch(e => {
            dispatch(actions.fetchListingsError(storableError(e)));
            throw e;
          });
      })
  };
}
