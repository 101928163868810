import { actions } from './reducer';
import { actions as marketplaceDataActions } from 'storage/slices/marketplaceData';
import { actions as bookingsPageActions } from 'storage/slices/manageBookingsPage';
import {
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITIONS,
} from '../../../util/transaction';
import { storableError } from '../../../util/errors';
import { types as sdkTypes, util as sdkUtil } from '../../../util/sdkLoader';
import {
  fetchCurrentUserHasListings,
  fetchCurrentUserHasOrders,
  fetchCurrentUserNotifications,
} from '../user';
import { denormalisedResponseEntities } from '../../../util/data';
import * as log from '../../../util/log';
import { authInfo } from '../auth';
import { actions as stripeConnectAccountActions } from 'storage/slices/stripeConnectAccount';
import { actions as userActions } from 'storage/slices/user';

const { UUID } = sdkTypes;

export const toggleCancelReservationModalVisibility = () => dispatch => {
  return dispatch(actions.toggleModalVisibility());
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(bookingsPageActions.fetchOrdersOrSalesRequest());

  const apiQueryParams = {
    only: 'order',
    lastTransitions: TRANSITIONS,
    include: ['provider', 'booking', 'listing', 'listing.images'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return sdk.currentUser.show({ include: ['favorites'] }).then(currentUserResponse => {
    const entities = denormalisedResponseEntities(currentUserResponse);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.show response');
    }
    const currentUser = entities[0];

    const favorites = currentUser?.attributes?.profile?.publicData?.favorites || [];
    dispatch(bookingsPageActions.fillFavoritesLists(favorites));

    dispatch(bookingsPageActions.clearFavoritesListsImages());
    favorites.forEach(list => {
      if (list.listings.length) {
        sdk.listings.show({
          id: new UUID(list.listings[0]),
          include: ['images'],
          'fields.image': ['variants.landscape-crop'],
          'limit.images': 1,
        }).then(response => {
          dispatch(actions.fillFavoritesListsImages({
            listId: list.id,
            url: response.data.included[0].attributes.variants['landscape-crop'].url
          }));
        }).catch((e) => {
          console.error(e)
        });
      }
    });
  }).then(() => {
    return sdk.transactions
      .query(apiQueryParams)
      .then(response => {
        dispatch(marketplaceDataActions.addMarketplaceEntities(response));
        dispatch(bookingsPageActions.fetchOrdersOrSalesSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(bookingsPageActions.fetchOrdersOrSalesError(storableError(e)));
        throw e;
      });
  })
};

export const loadDataForFavorites = (params, search) => (dispatch, getState, sdk) => {
  dispatch(userActions.currentUserShowRequest());
  const { isAuthenticated } = getState().auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(userActions.currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount', 'favorites'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeConnectAccountActions.stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(userActions.currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      const favorites = currentUser.attributes.profile.publicData.favorites || [];

      dispatch(bookingsPageActions.clearFavoritesListsImages());
      favorites.forEach(list => {
        list.listings.slice(0, 3).forEach(id => {
          const sdkId = new UUID(id);
          sdk.listings.show({
            id: sdkId,
            include: ['images'],
            'fields.image': ['variants.landscape-crop'],
            'limit.images': 1,
          }).then(response => {
            dispatch(bookingsPageActions.fillFavoritesListsImages({
              listId: list.id,
              url: response.data.included[0].attributes.variants['landscape-crop'].url
            }));
          });
        })
      })

      dispatch(bookingsPageActions.fillFavoritesLists(favorites));
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(userActions.currentUserShowError(storableError(e)));
    });
};

export const loadDataForFavoritesList = (params, search) => (dispatch, getState, sdk) => {
  const favoriteListId = params.id;

  dispatch(userActions.currentUserShowRequest());
  const { isAuthenticated } = getState().auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(userActions.currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  dispatch(bookingsPageActions.clearFavoritesEntities());

  const parameters = {
    include: ['profileImage', 'stripeAccount', 'favorites'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeConnectAccountActions.stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(userActions.currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      const favorites = currentUser.attributes.profile.publicData.favorites || [];
      dispatch(bookingsPageActions.fillFavoritesLists(favorites));
      const favoritesList = favorites?.find(list => list.id === favoriteListId);
      dispatch(bookingsPageActions.setSelectedFavoritesList(favoritesList));

      favoritesList?.listings.forEach(id => {
        const sdkId = new UUID(id);
        sdk.listings
          .show({
            id: sdkId,
            include: ['images', 'images.variants', 'author'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
            'fields.user': ['profile.displayName'],
          })
          .then(response => {
            dispatch(bookingsPageActions.fillFavoritesEntities(response.data));
          });
      });
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(userActions.currentUserShowError(storableError(e)));
    });
};

export const sendMessage = content => (dispatch, getState, sdk) => {
  const transactionId = getState().manageBookingsPage.selectedTransactionForCancelling;

  return sdk.messages.send({ transactionId, content });
};

export const cancelUpcomingBooking = () => (dispatch, getState, sdk) => {
  const transactionId = getState().manageBookingsPage.selectedTransactionForCancelling;
  dispatch(actions.setCancelRequestLoading());
  sdk.transactions
    .transition(
      { id: transactionId, transition: TRANSITION_CANCEL_BY_CUSTOMER, params: {} },
      { expand: true }
    )
    .then(() => {
      dispatch(actions.addCancelledBookingId(transactionId.uuid));
      dispatch(actions.toggleModalVisibility());
      dispatch(actions.clearSelectedTransaction());
      dispatch(actions.setCancelRequestLoading());
    })
    .catch(err => {
      console.log(err);
      dispatch(actions.setCancelRequestLoading());
      dispatch(actions.onErrorOnCancelling());
    });
};

export const cancelUpcomingBookingByProvider = () => (dispatch, getState, sdk) => {
  const transactionId = getState().manageBookingsPage.selectedTransactionForCancelling;
  dispatch(actions.setCancelRequestLoading());
  sdk.transactions
    .transition(
      { id: transactionId, transition: TRANSITION_CANCEL_BY_PROVIDER, params: {} },
      { expand: true }
    )
    .then(() => {
      dispatch(actions.addCancelledBookingId(transactionId.uuid));
      dispatch(actions.toggleModalVisibility());
      dispatch(actions.clearSelectedTransaction());
      dispatch(actions.setCancelRequestLoading());
    })
    .catch(err => {
      console.log(err);
      dispatch(actions.setCancelRequestLoading());
      dispatch(actions.onErrorOnCancelling());
    });
};
