import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { IconAppStore, IconGooglePlay } from '../../components';

import css from './SectionDownload.module.css';
import appStoreQRCode from './images/appStoreQRCode.png';

interface ISectionDownload {
  rootClassName?: string;
  className?: string;
}

const SectionDownload = ({
  rootClassName = '',
  className = '',
}: ISectionDownload) => {

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionDownload.title" />
      </div>
      <div className={css.content}>
        <img className={css.downloadQRCode} src={appStoreQRCode} alt="App Store QR code" />
        <div className={css.linksContainer}>
          <a className={css.downloadLink} href="https://apps.apple.com/us/app/umoozi/id1598757674" target='_blank'>
            <IconAppStore className={css.downloadLinkIcon} />
            <FormattedMessage id="SectionDownload.appStoreButton" />
          </a>
          {/* <a className={css.downloadLink} href="#">
            <IconGooglePlay className={css.downloadLinkIcon} />
            <FormattedMessage id="SectionDownload.googlePlayButton" />
          </a> */}
        </div>
      </div>
      <div className={css.hint}>
        <FormattedMessage id="SectionDownload.hint" />
      </div>
    </div>
  );
};

export default SectionDownload;
