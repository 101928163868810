import { actions } from './reducer';
import { storableError } from '../../../util/errors';
import { actions as marketplaceDataActions } from 'storage/slices/marketplaceData';
import { actions as bookingsPageActions } from 'storage/slices/manageBookingsPage';
import { convertUnitToSubUnit, unitDivisor } from '../../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../../util/dates';
import { parse } from '../../../util/urlHelpers';
import config from 'config';
import GeocoderMapbox from '../../../components/LocationAutocompleteInput/GeocoderMapbox';
import { denormalisedResponseEntities } from '../../../util/data';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { UUID } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

const GEOCODER_MAX_TOKENS = 20;
const GEOCODER_MAX_CHARACTERS = 256;

export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  dispatch(actions.searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
        start: formatDateStringToUTC(startDate),
        end: formatDateStringToUTC(endDate),
        // Availability can be full or partial. Default value is full.
        availability: 'full',
      }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    per_page: perPage,
  };

  await sdk.currentUser.show({ include: ['favorites'] }).then(response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length === 1) {
      const currentUser = entities[0];

      const favorites = currentUser?.attributes?.profile?.publicData?.favorites || [];
      dispatch(actions.setFavoriteLists(favorites));

      dispatch(actions.clearFavoritesListsImages());
      favorites.forEach(list => {
        if (list.listings.length) {
          sdk.listings.show({
            id: new UUID(list.listings[0]),
            include: ['images'],
            'fields.image': ['variants.landscape-crop'],
            'limit.images': 1,
          }).then(response => {
            dispatch(actions.fillFavoritesListsImages({
              listId: list.id,
              url: response.data.included[0].attributes.variants['landscape-crop'].url
            }));
          }).catch((e) => {
            console.error(e)
          });
        }
      });
    }

  })
  .catch(e => {});

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(actions.searchListingsError(storableError(e)));
      throw e;
    });
};

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(actions.searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(actions.searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const searchNearestVacationCities = (position) => (dispatch, getState, sdk) => {
  const geocoder = new GeocoderMapbox();
  const cities = [];

  sdk.listings.query({
    origin: position,
    per_page: 20,
    page: 1,
  }).then(res => {
    return Promise.all(
      res.data.data.map((listing) => {
        const listingAddress = listing.attributes.publicData.location?.address;
        const validAddressLength = Math.min(
          listingAddress?.split(/[-\s]/).slice(-GEOCODER_MAX_TOKENS).join(' ').length,
          GEOCODER_MAX_CHARACTERS
        );
        const validAddress = listingAddress?.substring(listingAddress.length - validAddressLength, listingAddress.length);
        if (listingAddress && validAddress) {
          return geocoder.getPlacePredictions(validAddress)
            .then(res => {
              if (cities.length < 3) {
                const firstPrediction = res.predictions[0];

                if (firstPrediction.context.some(c => c.id.startsWith('place.'))) {
                  const predictionPlace = firstPrediction.context.find(c => c.id.startsWith('place.'))?.text;

                  if (!cities.includes(predictionPlace)) {
                    cities.push(predictionPlace);
                    return predictionPlace;
                  }
                } else {
                  if (!cities.includes(firstPrediction.text)) {
                    cities.push(firstPrediction.text);
                    return firstPrediction.text;
                  }
                }
              }
            })
        }
      }).filter(e => !!e)
    );
  })
    .then(() => {
      return cities;
    })
    .then(cities => {
      return Promise.all(cities.map(city => {
        return geocoder.getPlacePredictions(city)
          .then(predictionsResponse => {
            return predictionsResponse.predictions[0];
          })
      }))
    })
    .then(res => {
      const locationQueries = res.map(p => {
        const country = p.context.find(c => c.id.startsWith('country')).text;
        const city = p.context.find(c => c.id.startsWith('region')).text;
        return {
          searchQuery: `?address=${city}%2C%20${country}&bounds=${p.bbox[3]}%2C${p.bbox[2]}%2C${p.bbox[1]}%2C${p.bbox[0]}&origin=${p.geometry.coordinates[0]}%2C${p.geometry.coordinates[1]}`,
          city,
          place: p.text,
          key: p.id,
        }
      })

      dispatch(actions.setNearestLocations(locationQueries))
    })
    .catch(() => {
      dispatch(actions.setNearestLocationsFetchError(true));
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};
