import { actions } from './reducer';
import { storableError } from '../../../util/errors';

export const resetPassword = (email, passwordResetToken, newPassword) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(actions.resetPasswordRequest());
  const params = { email, passwordResetToken, newPassword };
  return sdk.passwordReset
    .reset(params)
    .then(() => dispatch(actions.resetPasswordSuccess()))
    .catch(e => dispatch(actions.resetPasswordError(storableError(e))));
};
