export enum UnitType {
  LINE_ITEM_NIGHT = 'line-item/night',
  LINE_ITEM_DAY = 'line-item/day',
  LINE_ITEM_UNITS = 'line-item/units',
}

export enum ERROR_CODES {
  ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND = 'transaction-listing-not-found',
  ERROR_CODE_TRANSACTION_INVALID_TRANSITION = 'transaction-invalid-transition',
  ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_CUSTOMER = 'transaction-already-reviewed-by-customer',
  ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_PROVIDER = 'transaction-already-reviewed-by-provider',
  ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE = 'transaction-booking-time-not-available',
  ERROR_CODE_PAYMENT_FAILED = 'transaction-payment-failed',
  ERROR_CODE_CHARGE_ZERO_PAYIN = 'transaction-charge-zero-payin',
  ERROR_CODE_CHARGE_ZERO_PAYOUT = 'transaction-charge-zero-payout',
  ERROR_CODE_EMAIL_TAKEN = 'email-taken',
  ERROR_CODE_EMAIL_NOT_FOUND = 'email-not-found',
  ERROR_CODE_TOO_MANY_VERIFICATION_REQUESTS = 'email-too-many-verification-requests',
  ERROR_CODE_UPLOAD_OVER_LIMIT = 'request-upload-over-limit',
  ERROR_CODE_VALIDATION_INVALID_PARAMS = 'validation-invalid-params',
  ERROR_CODE_VALIDATION_INVALID_VALUE = 'validation-invalid-value',
  ERROR_CODE_NOT_FOUND = 'not-found',
  ERROR_CODE_FORBIDDEN = 'forbidden',
  ERROR_CODE_MISSING_STRIPE_ACCOUNT = 'transaction-missing-stripe-account',
}

export enum Transitions {
  STATE_INITIAL = 'initial',
  STATE_ENQUIRY = 'enquiry',
  STATE_PENDING_PAYMENT = 'pending-payment',
  STATE_PAYMENT_EXPIRED = 'payment-expired',
  STATE_PREAUTHORIZED = 'preauthorized',
  STATE_DECLINED = 'declined',
  STATE_ACCEPTED = 'accepted',
  STATE_CANCELED = 'canceled',
  STATE_DELIVERED = 'delivered',
  STATE_REVIEWED = 'reviewed',
  STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer',
  STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider',
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
}

export enum TX_TRANSITION_ACTORS {
  TX_TRANSITION_ACTOR_CUSTOMER = 'customer',
  TX_TRANSITION_ACTOR_PROVIDER = 'provider',
  TX_TRANSITION_ACTOR_SYSTEM = 'system',
  TX_TRANSITION_ACTOR_OPERATOR = 'operator',
}

export enum BOOKING_STATES {
  BOOKING_STATE_PENDING = 'pending',
  BOOKING_STATE_ACCEPTED = 'accepted',
  BOOKING_STATE_DECLINED = 'declined',
  BOOKING_STATE_CANCELLED = 'cancelled',
  BOOKING_STATE_CANCELLED_BY_CUSTOMER = 'cancelled-by-customer',
  BOOKING_STATE_CANCELLED_BY_PROVIDER = 'cancelled-by-provider',
}

export enum LISTING_STATES {
  LISTING_STATE_DRAFT = 'draft',
  LISTING_STATE_PENDING_APPROVAL = 'pendingApproval',
  LISTING_STATE_PUBLISHED = 'published',
  LISTING_STATE_CLOSED = 'closed',
}

export enum REVIEW_TYPE {
  ofProvider = 'ofProvider',
  ofCustomer = 'ofCustomer',
}

export enum SITE_LANGUAGE {
  en = 'en',
  fr = 'fr',
  es = 'es',
}
