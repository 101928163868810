import { createSlice } from '@reduxjs/toolkit';
import unionWith from 'lodash/unionWith';

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  isLocationPermissionDenied: false,
  nearestLocations: [],
  nearestLocationsFetchError: false,
  favoriteLists: [],
  favoritesListsImages: null,
};

const resultIds = data => data.data.map(l => l.id);

const slice = createSlice({
  name: 'searchPage',
  initialState,
  reducers: {
    searchListingsRequest: (state, action) => {
      Object.assign(state, {
        searchParams: action.payload,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      });
    },
    searchListingsSuccess: (state, action) => {
      const { payload } = action;

      Object.assign(state, {
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      });
    },
    searchListingsError: (state, action) => {
      const { payload } = action;
      // eslint-disable-next-line no-console
      console.error(payload);

      Object.assign(state, {
        searchInProgress: false,
        searchListingsError: payload,
      });
    },
    searchMapListingsRequest: (state) => {
      state.searchMapListingsError = null;
    },
    searchMapListingsSuccess: (state, action) => {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(action.payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );

      state.searchMapListingIds = searchMapListingIds;
    },
    searchMapListingsError: (state, action) => {
      const { payload } = action;
      // eslint-disable-next-line no-console
      console.error(payload);

      state.searchMapListingsError = payload;
    },
    setActiveListing: (state, action) => {
      state.activeListingId = action.payload;
    },
    setClosestCities: (state, action) => {
      Object.assign(state, { closestCities: action.payload })
    },
    setNearestLocations: (state, action) => {
      Object.assign(state, { nearestLocations: action.payload })
    },
    setIsLocationPermissionDenied: (state, action) => {
      Object.assign(state, { isLocationPermissionDenied: action.payload })
    },
    setNearestLocationsFetchError: (state, action) => {
      Object.assign(state, { nearestLocationsFetchError: action.payload })
    },
    setFavoriteLists: (state, action) => {
      Object.assign(state, { favoriteLists: action.payload })
    },
    fillFavoritesListsImages: (state, action) => {
      Object.assign(state, {
        favoritesListsImages: {
          ...state.favoritesListsImages,
          [action.payload.listId]: [
            ...(state.favoritesListsImages?.[action.payload.listId] || []),
            action.payload.url
          ]
        },
      });
    },
    clearFavoritesListsImages: (state, action) => {
      Object.assign(state, {
        favoritesListsImages: null,
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
