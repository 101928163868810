import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import { FormattedMessage } from '../../util/reactIntl';
import { FieldDateRangeController } from '../index';
import { manageDisableScrolling } from '../../storage/slices/ui';

import css from '../DateRangeModal/DateRangeModal.module.css';

const DateRangeModal = (props) => {
  const { setOpen, isOpen, controllerRef } = props;
  const [openedModal, setOpenedModal] = useState(false);

  useEffect(() => {
    if(isOpen)
     setOpenedModal(isOpen);
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      onManageDisableScrolling={manageDisableScrolling}
      id='dateRange'
    >
      <div className={css.modalTitle}>
        <FormattedMessage id='TopbarNewSearchForm.dateRangeTitle' />
      </div>
      <div className={css.dateRangeContainer}>
        {openedModal && (<FieldDateRangeController
          name='dates'
          controllerRef={controllerRef}
        />)}
      </div>
      <div className={css.buttonContainer}>
        <button
          type='button'
          className={css.nextButton}
          onClick={() => {
            setOpen(false);
          }}
        >
          <FormattedMessage id='TopbarNewSearchForm.nextButton' />
        </button>
      </div>
    </Modal>
  )
};

export default DateRangeModal;
