import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { FieldCheckbox, FieldTextInput } from "../../components";
import arrayMutators from "final-form-arrays";
import { FormattedMessage } from "../../util/reactIntl";
import { bookingCancellationReasonsProvider, bookingCancellationReasonsCustomer } from "../../marketplace-custom-config";
import * as validators from '../../util/validators';

import css from "./CancelReservationForm.module.css";

const MAX_SYMBOLS = 30;

const CancelReservationForm = ({
  visibilityHandler,
  clearSelectedTransaction,
  cancelUpcomingBooking,
  isProvider,
}) => {
  const handleSubmit = data => {
    // todo: handle submitted data. Send via message to the owner?
    cancelUpcomingBooking(data);
  };

  const handleCancel = () => {
    visibilityHandler();
    clearSelectedTransaction();
  };

  const bookingCancellationReasons = isProvider ? bookingCancellationReasonsProvider : bookingCancellationReasonsCustomer;

  const fieldMaxLength = validators.maxLength(`Maximum ${MAX_SYMBOLS} symbols`, MAX_SYMBOLS);
  const fieldRequired = validators.required('Additional comment is required');

  return (
    <FinalForm
      mutators={{ ...arrayMutators }}
      onSubmit={handleSubmit}
      validateOnBlur
      render={formRenderProps => {
        const isOtherReason = formRenderProps.values?.bookingCancellationReasons?.includes('other');
        const additionalCommentsLabelHint = !isOtherReason ? <FormattedMessage id="CancelReservationForm.additionalCommentsLabelHint"/> : null;

        return (
          <form onSubmit={formRenderProps.handleSubmit}>
            {
              bookingCancellationReasons.map(reason => (
                <FieldCheckbox
                  key={reason.id}
                  id={reason.id}
                  name={reason.name}
                  value={reason.value}
                  label={reason.label}
                  svgClassName={css.checkBoxSvg}
                />
              ))
            }
            <label className={css.additionalLabel} htmlFor="additional">
              <FormattedMessage
                id="CancelReservationForm.additionalCommentsLabel"
                values={{ optionalHint: additionalCommentsLabelHint }}
              />
            </label>
            <FieldTextInput
              type="text"
              name="additional"
              id="additional"
              validate={isOtherReason
                ? validators.composeValidators(fieldMaxLength, fieldRequired)
                : fieldMaxLength
              }
            />
            <div className={css.buttonsContainer}>
              <button
                type="button"
                className={css.cancelButton}
                onClick={handleCancel}
              >
                <FormattedMessage id="CancelReservationForm.cancelButtonTitle" />
              </button>
              <button type="submit" className={css.submitButton}>
                <FormattedMessage id="CancelReservationForm.sendButtonTitle" />
              </button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default CancelReservationForm;
