import { Dispatch } from "react";
import { changeUserLanguageReq, getUserLanguageReq } from "../../../util/api";

import { SITE_LANGUAGE } from "../../../typings/enums";
import { actions } from './reducer';

export const changeUserLanguage = (newLanguage: SITE_LANGUAGE) => async (dispatch: Dispatch<unknown>) => {
  let newLanguageRes;
  try {
    newLanguageRes = await changeUserLanguageReq(newLanguage)
  } catch (err) {
    //do nothing
  }
  dispatch(actions.changeSiteLanguage(newLanguageRes));
};

export const getUserLanguage = () => async (dispatch: Dispatch<unknown>): Promise<SITE_LANGUAGE> => {
  let languageRes;
  dispatch(actions.loadingStart());

  try {
    languageRes = await getUserLanguageReq()
  } catch (err) {
    //do nothing
    dispatch(actions.loadingEnd());
    return SITE_LANGUAGE.en;
  }
  const language = languageRes || SITE_LANGUAGE.en;
  dispatch(actions.changeSiteLanguage(languageRes || SITE_LANGUAGE.en));
  dispatch(actions.loadingEnd());

  return language;
};
