import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const slice = createSlice({
  name: 'passwordResetPage',
  initialState,
  reducers: {
    resetPasswordRequest: (state) => {
      Object.assign(state, {
        resetPasswordInProgress: true,
        resetPasswordError: null
      });
    },
    resetPasswordSuccess: (state) => {
      Object.assign(state, {
        resetPasswordInProgress: false
      });
    },
    resetPasswordError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
      Object.assign(state, {
        resetPasswordInProgress: false,
        resetPasswordError: action.payload
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
