import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';
import { FormattedMessage } from '../../util/reactIntl';

const PrivacyPolicy = props => {
  const {rootClassName, className} = props;
  const classes = classNames(rootClassName || css.root, className);
  
  const email = <a href="mailto:customerservice@umoozi.com">
    customerservice@umoozi.com
  </a>

  const privacyPolicyText = [
    {
      type: 'title',
      id: 'PrivacyPolicy.part1Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part1Text',
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part2Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part2Text',
    },
    {
      type: 'subtitle',
      id: 'PrivacyPolicy.part2Sub1Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part2Sub1Text',
    },
    {
      type: 'subtitle',
      id: 'PrivacyPolicy.part2Sub2Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part2Sub2Text',
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part3Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part3Text',
      values: {
        br: <br />
      },
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part4Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part4Text',
    },
    {
      type: 'subtitle',
      id: 'PrivacyPolicy.part4Sub1Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part4Sub1Text',
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part5Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part5Text',
      values: {
        br: <br />
      },
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part6Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part6Text',
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part7Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part7Text',
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part8Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part8Text',
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.part9Title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.part9Text',
    },
    {
      type: 'title',
      id: 'PrivacyPolicy.nonDiscriminationPolicy.title',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.nonDiscriminationPolicy.part1Text',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.nonDiscriminationPolicy.part2Text',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.nonDiscriminationPolicy.part3Text',
    },
    {
      type: 'text',
      id: 'PrivacyPolicy.nonDiscriminationPolicy.part4Text',
      values: {
        email: email,
      },
    },
  ];

  // prettier-ignore
  return (
    <div className={classes}>
      {privacyPolicyText.map(privacyPolicyTextItem => {
        const ParagraphContainer = ({ children }) => (
          <>
            {privacyPolicyTextItem.type === 'title' && <h2>{children}</h2>}
            {privacyPolicyTextItem.type === 'subtitle' && <h3>{children}</h3>}
            {privacyPolicyTextItem.type === 'text' && <p>{children}</p>}
          </>
        );

        return (
          <ParagraphContainer>
            <FormattedMessage id={privacyPolicyTextItem.id} values={privacyPolicyTextItem.values} />
          </ParagraphContainer>
        );
      })}
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const {string} = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
