import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { createSlice } from '@reduxjs/toolkit';

// ================ Reducer ================ //

const initialState = {
  addPaymentMethodInProgress: null,
  addPaymentMethodError: null,
  deletePaymentMethodInProgress: null,
  deletePaymentMethodError: null,
  createStripeCustomerInProgress: null,
  createStripeCustomerError: null,
  stripeCustomer: null,
};

export const slice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      Object.assign(state, {
        ...initialState,
        ...action.payload,
      });
    },
    createStripeCustomerRequest: (state) => {
      return Object.assign(state, {
        createStripeCustomerError: null,
        createStripeCustomerInProgress: true
      })
    },
    createStripeCustomerSuccess: (state, action) => {
      return Object.assign(state, {
        createStripeCustomerInProgress: false,
        stripeCustomer: action.payload,
      });
    },
    createStripeCustomerError: (state, action) => {
      return Object.assign(state, {
        createStripeCustomerError: action.payload,
        createStripeCustomerInProgress: false,
      });
    },
    addPaymentMethodRequest: (state) => {
      return Object.assign(state, {
        addPaymentMethodError: null,
        addPaymentMethodInProgress: true
      });
    },
    addPaymentMethodSuccess: (state, action) => {
      return Object.assign(state, {
        addPaymentMethodInProgress: false,
        stripeCustomer: action.payload,
      });
    },
    addPaymentMethodError: (state, action) => {
      return Object.assign(state, {
        addPaymentMethodError: action.payload,
        addPaymentMethodInProgress: false,
      });
    },
    deletePaymentMethodRequest: (state) => {
      return Object.assign(state, {
        deletePaymentMethodError: null,
        deletePaymentMethodInProgress: true
      });
    },
    deletePaymentMethodSuccess: (state) => {
      return Object.assign(state, {
        deletePaymentMethodInProgress: false,
      });
    },
    deletePaymentMethodError: (state, action) => {
      return Object.assign(state, {
        deletePaymentMethodError: action.payload,
        deletePaymentMethodInProgress: false,
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;

// ================ Thunks ================ //

export const createStripeCustomer = stripePaymentMethodId => (dispatch, getState, sdk) => {
  dispatch(actions.createStripeCustomerRequest());
  return sdk.stripeCustomer
    .create({ stripePaymentMethodId }, { expand: true, include: ['defaultPaymentMethod'] })
    .then(response => {
      const stripeCustomer = response.data.data;
      dispatch(actions.createStripeCustomerSuccess(stripeCustomer));
      return stripeCustomer;
    })
    .catch(e => {
      log.error(storableError(e), 'create-stripe-user-failed');
      dispatch(actions.createStripeCustomerError(storableError(e)));
    });
};

export const addPaymentMethod = stripePaymentMethodId => (dispatch, getState, sdk) => {
  dispatch(actions.addPaymentMethodRequest());
  return sdk.stripeCustomer
    .addPaymentMethod({ stripePaymentMethodId }, { expand: true })
    .then(response => {
      const stripeCustomer = response.data.data;
      dispatch(actions.addPaymentMethodSuccess(stripeCustomer));
      return stripeCustomer;
    })
    .catch(e => {
      log.error(storableError(e), 'add-payment-method-failed');
      dispatch(actions.addPaymentMethodError(storableError(e)));
    });
};

export const deletePaymentMethod = () => (dispatch, getState, sdk) => {
  dispatch(actions.deletePaymentMethodRequest());
  return sdk.stripeCustomer
    .deletePaymentMethod({}, { expand: true })
    .then(response => {
      const stripeCustomer = response.data.data;
      dispatch(actions.deletePaymentMethodSuccess(stripeCustomer));
      return stripeCustomer;
    })
    .catch(e => {
      log.error(storableError(e), 'add-payment-method-failed');
      dispatch(actions.deletePaymentMethodError(storableError(e)));
    });
};

export const updatePaymentMethod = stripePaymentMethodId => (dispatch, getState, sdk) => {
  return dispatch(deletePaymentMethod())
    .then(() => {
      return dispatch(addPaymentMethod(stripePaymentMethodId));
    })
    .catch(e => {
      log.error(storableError(e), 'updating-payment-method-failed');
    });
};

// This function helps to choose correct thunk function
export const savePaymentMethod = (stripeCustomer, stripePaymentMethodId) => (
  dispatch,
  getState,
  sdk
) => {
  const hasAlreadyDefaultPaymentMethod =
    stripeCustomer && stripeCustomer.defaultPaymentMethod && stripeCustomer.defaultPaymentMethod.id;

  const savePromise =
    !stripeCustomer || !stripeCustomer.id
      ? dispatch(createStripeCustomer(stripePaymentMethodId))
      : hasAlreadyDefaultPaymentMethod
        ? dispatch(updatePaymentMethod(stripePaymentMethodId))
        : dispatch(addPaymentMethod(stripePaymentMethodId));

  return savePromise
    .then(response => {
      const {
        createStripeCustomerError,
        addPaymentMethodError,
        deletePaymentMethodError,
      } = getState().paymentMethods;

      // If there are any errors, return those errors
      if (createStripeCustomerError || addPaymentMethodError || deletePaymentMethodError) {
        return {
          errors: { createStripeCustomerError, addPaymentMethodError, deletePaymentMethodError },
        };
      }
      return response;
    })
    .catch(e => {
      // errors are already catched in other thunk functions.
    });
};
