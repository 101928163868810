import { createSlice } from '@reduxjs/toolkit';

const initialState: TransactionPage = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a: Array<any>, b: Array<any>) => {
  return a
    .filter((aEntity: any) => !b.find((bEntity: any) => aEntity.id.uuid === bEntity.id.uuid))
    .concat(b);
};

const slice = createSlice({
  name: 'transactionPage',
  initialState,
  reducers: {
    setInitialValues: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        ...initialState,
        ...action.payload,
      });
    },
    fetchTransactionRequest: (state: TransactionPage) => {
      Object.assign(state, {
        fetchTransactionInProgress: true,
        fetchTransactionError: null
      });
    },
    fetchTransactionSuccess: (state: TransactionPage, action: any) => {
      const transactionRef = { id: action.payload.data.data.id, type: 'transaction' };

      Object.assign(state, {
        fetchTransactionInProgress: false,
        transactionRef
      });
    },
    fetchTransactionError: (state: TransactionPage, action: any) => {
      console.error(action.payload); // eslint-disable-line

      Object.assign(state, {
        fetchTransactionInProgress: false,
        fetchTransactionError: action.payload
      });
    },
    fetchTransitionsRequest: (state: TransactionPage) => {
      Object.assign(state, {
        fetchTransitionsInProgress: true,
        fetchTransitionsError: null
      });
    },
    fetchTransitionsSuccess: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        fetchTransitionsInProgress: false,
        processTransitions: action.payload
      });
    },
    fetchTransitionsError: (state: TransactionPage, action: any) => {
      console.error(action.payload); // eslint-disable-line

      Object.assign(state, {
        fetchTransitionsInProgress: false,
        fetchTransitionsError: action.payload
      });
    },
    acceptSaleRequest: (state: TransactionPage) => {
      Object.assign(state, {
        acceptInProgress: true,
        acceptSaleError: null,
        declineSaleError: null
      });
    },
    acceptSaleSuccess: (state: TransactionPage) => {
      Object.assign(state, {
        acceptInProgress: false
      });
    },
    acceptSaleError: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        acceptInProgress: false,
        acceptSaleError: action.payload
      });
    },
    declineSaleRequest: (state: TransactionPage) => {
      Object.assign(state, {
        declineInProgress: true,
        declineSaleError: null,
        acceptSaleError: null
      });
    },
    declineSaleSuccess: (state: TransactionPage) => {
      Object.assign(state, {
        declineInProgress: false,
      });
    },
    declineSaleError: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        declineInProgress: false,
        declineSaleError: action.payload
      });
    },
    fetchMessagesRequest: (state: TransactionPage,) => {
      Object.assign(state, {
        fetchMessagesInProgress: true,
        fetchMessagesError: null
      });
    },
    fetchMessagesSuccess: (state: TransactionPage, action: any) => {
      const { page, totalItems, totalPages } = action.payload.pagination;

      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > page ? state.oldestMessagePageFetched : page;

      Object.assign(state, {
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, action.payload.messages),
        totalMessages: totalItems,
        totalMessagePages: totalPages,
        oldestMessagePageFetched,
      });
    },
    fetchMessagesError: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        fetchMessagesInProgress: false,
        fetchMessagesError: action.payload
      });
    },
    sendMessageRequest: (state: TransactionPage) => {
      Object.assign(state, {
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      });
    },
    sendMessageSuccess: (state: TransactionPage) => {
      Object.assign(state, {
        sendMessageInProgress: false
      });
    },
    sendMessageError: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        sendMessageInProgress: false,
        sendMessageError: action.payload
      });
    },
    sendReviewRequest: (state: TransactionPage) => {
      Object.assign(state, {
        sendReviewInProgress: true,
        sendReviewError: null
      });
    },
    sendReviewSuccess: (state: TransactionPage) => {
      Object.assign(state, {
        sendReviewInProgress: false
      });
    },
    sendReviewError: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        sendReviewInProgress: false,
        sendReviewError: action.payload
      });
    },
    fetchTimeSlotsRequest: (state: TransactionPage) => {
      Object.assign(state, {
        fetchTimeSlotsError: null
      });
    },
    fetchTimeSlotsSuccess: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        timeSlots: action.payload
      });
    },
    fetchTimeSlotsError: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        fetchTimeSlotsError: action.payload
      });
    },
    fetchLineItemsRequest: (state: TransactionPage) => {
      Object.assign(state, {
        fetchLineItemsInProgress: true,
        fetchLineItemsError: null
      });
    },
    fetchLineItemsSuccess: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        fetchLineItemsInProgress: false,
        lineItems: action.payload
      });
    },
    fetchLineItemsError: (state: TransactionPage, action: any) => {
      Object.assign(state, {
        fetchLineItemsInProgress: false,
        fetchLineItemsError: action.payload
      });
    },
  },
});

export const actions = slice.actions;

export default slice.reducer;
