import { createSlice } from '@reduxjs/toolkit';
/**
 * This file contains Action types, Action creators, and reducer of global
 * LocationFilter. Global actions can be used in multiple pages.
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */

// Actions
export const CHANGE_LOCATION = 'app/LocationFilter/CHANGE_LOCATION';

// Reducer
export const slice = createSlice({
  name: 'locationFilter',
  initialState: {},
  reducers: {
    changeLocation: (_, action) => {
      return action.payload
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;

// Action types
export const changeLocationFilter = location => ({ type: CHANGE_LOCATION, payload: location });
