import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const slice = createSlice({
  name: 'profilePage',
  initialState,
  reducers: {
    setInitialState: (state) => {
      Object.assign(state, {
        ...initialState,
      });
    },
    showUserRequest: (state, action) => {
      Object.assign(state, {
        userShowError: null, 
        userId: action.payload.uuid,
      });
    },
    showUserSuccess: (state) => {
      return state;
    },
    showUserError: (state, action) => {
      state.userShowError = action.payload;
    },
    queryListingsRequest: (state, action) => {
      Object.assign(state, {
        userListingRefs: action.payload.uuid === state.userId ? state.userListingRefs : [],
        queryListingsError: null,
      });
    },
    queryListingsSuccess: (state, action) => {
      state.userListingRefs = action.payload;
    },
    queryListingsError: (state, action) => {
      Object.assign(state, {
        userListingRefs: [], 
        queryListingsError: action.payload
      });
    },
    queryReviewsRequest: (state) => {
      state.queryReviewsError = null;
    },
    queryReviewsSuccess: (state, action) => {
      state.reviews = action.payload;
    },
    queryReviewsError: (state, action) => {
      Object.assign(state, {
        reviews: [], 
        queryReviewsError: action.payload,
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
