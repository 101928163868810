import React, { useEffect, useRef, useState } from 'react';
import { Form as FinalForm, FormProps } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { Form, IconSearch } from '../../components';

import DestinationModal from '../../components/DestinationModal/DestinationModal';
import DateRangeModal from '../../components/DateRangeModal/DateRangeModal';
import css from './TopbarNewSearchForm.module.css';
import { IntlShape } from 'react-intl/src/types';

const formatDate = (date: string | number | Date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [year, month, day].join('-');
};

interface TopBarNewSearchFormProps extends FormProps {
  intl: IntlShape;
  handleDate: (formattedForSearchDate: string) => void;
  onSubmit: (values: any) => void;
  rootClassName: string;
}

const TopbarNewSearchForm = ({ intl, handleDate, onSubmit, rootClassName }: TopBarNewSearchFormProps) => {
  const controllerRef = useRef<any>({});
  const [locationModalIsOpen, setLocationModalIsOpen] = useState(false);
  const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = useState(false);
  const [searchLocation, setSearchLocation] = useState({
    search: "",
  });

  const startDate = controllerRef.current.props?.value?.startDate || null;
  const endDate = controllerRef.current.props?.value?.endDate || null;

  const format: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const searchDateFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  const formattedStartDate = intl.formatDate(startDate, format);
  const formattedEndDate = intl.formatDate(endDate, format);
  const formattedForSearchStartDate = intl.formatDate(startDate, searchDateFormat);
  const formattedForSearchEndDate = intl.formatDate(endDate, searchDateFormat);

  const date = formattedStartDate !== formattedEndDate ?
    `${formattedStartDate} - ${formattedEndDate}` : "";
  const formattedForSearchDate = formattedStartDate !== formattedEndDate ?
    `${formatDate(formattedForSearchStartDate)},${formatDate(formattedForSearchEndDate)}` : "";

  const handleOpenLocationModal = () => {
    setLocationModalIsOpen(true);
  };

  const handleOpenDateRangeModal = () => {
    setDateRangeModalIsOpen(true);
  };

  useEffect(() => {
    handleDate(formattedForSearchDate);
  }, [dateRangeModalIsOpen, formattedForSearchDate, handleDate]);

  return (
    <FinalForm
      onSubmit={(values) => {
        if (!searchLocation.search) {
          handleOpenLocationModal();
        } else if (!date) {
          handleOpenDateRangeModal();
        } else {
          onSubmit(values);
        }
      }}
      intl={intl}
      rootClassName={rootClassName}
      render={(fieldRenderProps) => {
        const destinationLabel = intl.formatMessage({
          id: 'TopbarNewSearchForm.destinationLabel',
        });
        const dateRangeLabel = intl.formatMessage({
          id: 'TopbarNewSearchForm.dateRangeLabel',
        });
        const destinationPlaceholder = intl.formatMessage({
          id: 'TopbarNewSearchForm.destinationPlaceholder',
        });
        const dateRangePlaceholder = intl.formatMessage({
          id: 'TopbarNewSearchForm.dateRangePlaceholder',
        });
        const classes = classNames(rootClassName || css.root);

        return (
          <Form
            className={classes}
            onSubmit={fieldRenderProps.handleSubmit}
            autoComplete="off"
          >
            <div className={css.container}>
              <label className={css.label} htmlFor="destination">
                {destinationLabel}
              </label>
              <div className={css.inputContainer}>
                <input
                  className={css.input}
                  type='text'
                  id="destination"
                  placeholder={destinationPlaceholder}
                  onFocus={handleOpenLocationModal}
                  value={searchLocation.search ? searchLocation.search : ""}
                  readOnly
                />
              </div>
            </div>
            <div className={css.container}>
              <label className={css.label} htmlFor="dateRange">
                {dateRangeLabel}
              </label>
              <div className={css.inputContainer}>
                <input
                  className={css.input}
                  type='text'
                  id="dateRange"
                  placeholder={dateRangePlaceholder}
                  onFocus={handleOpenDateRangeModal}
                  value={date ? date : ""}
                  readOnly
                />
              </div>
            </div>
            <button type='submit' className={css.searchButton}>
              <FormattedMessage id='TopbarNewSearchForm.searchButton' />
              <IconSearch className={css.searchIcon} />
            </button>
            <DestinationModal
              openLocationModal={locationModalIsOpen}
              setOpenLocationModal={setLocationModalIsOpen}
              setOpenDateRangeModal={(nextState: boolean) => {
                !date && setDateRangeModalIsOpen(nextState);
              }}
              intl={intl}
              setSearchLocation={setSearchLocation}
            />
            <DateRangeModal
              isOpen={dateRangeModalIsOpen}
              setOpen={setDateRangeModalIsOpen}
              controllerRef={controllerRef}
            />
          </Form>
        );
      }}
    />
  );
}

export default TopbarNewSearchForm;
