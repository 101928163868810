import React, { Component } from 'react';
import { string, func, arrayOf, shape, number } from 'prop-types';
import classNames from 'classnames';

import { Menu, MenuContent, MenuItem, MenuLabel } from '../../components';
import css from './SortByPopup.module.css';

const SortByIcon = () => {
  return (
    <svg className={css.icon} width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 3.4758C0.152978 3.62343 0.057771 3.81149 0.0192034 4.01623C-0.0193641 4.22096 0.000439122 4.43317 0.0761092 4.62602C0.151779 4.81887 0.279919 4.98372 0.444329 5.09971C0.608738 5.2157 0.802037 5.27763 0.999787 5.27768H2.99979V11.6112C2.99979 11.8911 3.10514 12.1596 3.29268 12.3576C3.48022 12.5555 3.73457 12.6667 3.99979 12.6667C4.265 12.6667 4.51936 12.5555 4.70689 12.3576C4.89443 12.1596 4.99979 11.8911 4.99979 11.6112V5.27768H6.99979C7.19754 5.27763 7.39083 5.2157 7.55524 5.09971C7.71965 4.98372 7.84779 4.81887 7.92346 4.62602C7.99914 4.43317 8.01894 4.22096 7.98037 4.01623C7.9418 3.81149 7.8466 3.62343 7.70679 3.4758L4.70679 0.30906C4.51926 0.111169 4.26495 0 3.99979 0C3.73462 0 3.48031 0.111169 3.29279 0.30906L0.292787 3.4758ZM8.29279 15.5242C8.15298 15.3766 8.05777 15.1885 8.0192 14.9838C7.98064 14.779 8.00044 14.5668 8.07611 14.374C8.15178 14.1811 8.27992 14.0163 8.44433 13.9003C8.60874 13.7843 8.80204 13.7224 8.99979 13.7223H10.9998V7.38884C10.9998 7.10888 11.1051 6.84039 11.2927 6.64243C11.4802 6.44447 11.7346 6.33326 11.9998 6.33326C12.265 6.33326 12.5194 6.44447 12.7069 6.64243C12.8944 6.84039 12.9998 7.10888 12.9998 7.38884V13.7223H14.9998C15.1975 13.7224 15.3908 13.7843 15.5552 13.9003C15.7197 14.0163 15.8478 14.1811 15.9235 14.374C15.9991 14.5668 16.0189 14.779 15.9804 14.9838C15.9418 15.1885 15.8466 15.3766 15.7068 15.5242L12.7068 18.6909C12.5193 18.8888 12.265 19 11.9998 19C11.7346 19 11.4803 18.8888 11.2928 18.6909L8.29279 15.5242Z" fill="#4A4A4A"/>
    </svg>
  );
};

class SortByPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(urlParam, option) {
    this.setState({ isOpen: false });
    this.props.onSelect(urlParam, option);
  }

  render() {
    const {
      rootClassName,
      className,
      urlParam,
      label,
      options,
      initialValue,
      contentPlacementOffset,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <Menu
        className={classes}
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel className={css.menuLabel}>
          <SortByIcon />
          {label}
        </MenuLabel>
        <MenuContent className={css.menuContent}>
          <MenuItem key="sort-by">
            <h4 className={css.menuHeading}>{label}</h4>
          </MenuItem>
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            // menu item border class
            const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;

            return (
              <MenuItem key={option.key}>
                <button
                  className={css.menuItem}
                  disabled={option.disabled}
                  onClick={() => (selected ? null : this.selectOption(urlParam, option.key))}
                >
                  <span className={menuItemBorderClass} />
                  {option.longLabel || option.label}
                </button>
              </MenuItem>
            );
          })}
        </MenuContent>
      </Menu>
    );
  }
}

SortByPopup.defaultProps = {
  rootClassName: null,
  className: null,
  menuLabelRootClassName: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SortByPopup.propTypes = {
  rootClassName: string,
  className: string,
  menuLabelRootClassName: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  contentPlacementOffset: number,
};

export default SortByPopup;
