import { createSlice } from '@reduxjs/toolkit';

export const DISABLE_SCROLLING = 'app/UI/DISABLE_SCROLLING';

const initialState = {
  disableScrollRequests: [],
};

export const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    disableScrolling: (state, action) => {
      const { componentId, disableScrolling } = action.payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.ui;
  return disableScrollRequests.some(r => r.disableScrolling);
};
