import { actions } from './reducer';
import { storableError } from '../../../util/errors';
import { deleteUserAccount } from '../../../util/api';

export const deleteAccount = params => async (dispatch, getState, sdk) => {
  await dispatch(actions.deleteAccountRequest());
  const { currentPassword } = params;

  return deleteUserAccount({ currentPassword })
    .then(() => {
      dispatch(actions.deleteAccountSuccess());
      return true;
    })
    .catch(e => {
      dispatch(actions.deleteAccountError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on deleteAccount submit handler
      throw e;
    });
};

export const deleteAccountClear = params => (dispatch, getState, sdk) => {
  dispatch(actions.deleteAccountClear());
};

export const resetPassword = email => (dispatch, getState, sdk) => {
  dispatch(actions.resetPasswordRequest());
  return sdk.passwordReset
    .request({ email })
    .then(() => dispatch(actions.resetPasswordSuccess()))
    .catch(e => dispatch(actions.resetPasswordError(storableError(e))));
};
