import { createSlice } from '@reduxjs/toolkit';

import { updatedEntities } from '../../../util/data';

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  nextListingsPage: 2,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const slice = createSlice({
  name: 'manageListingsPage',
  initialState,
  reducers: {
    fetchListingsRequest: (state, action) => {
      Object.assign(state, {
        queryParams: action.payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      });
    },
    fetchListingsSuccess: (state, action) => {
      Object.assign(state, {
        currentPageResultIds: resultIds(action.payload.data),
        pagination: action.payload.data.meta,
        queryInProgress: false,
      });
    },
    fetchListingsError: (state, action) => {
      Object.assign(state, {
        queryInProgress: false,
        queryListingsError: action.payload,
      });
    },
    openListingRequest: (state, action) => {
      Object.assign(state, {
        openingListing: action.payload.listingId,
        openingListingError: null,
      });
    },
    openListingSuccess: (state, action) => {
      Object.assign(state, {
        ...updateListingAttributes(state, action.payload.data),
        openingListing: null,
      });
    },
    openListingError: (state, action) => {
      console.error(action.payload);
      Object.assign(state, {
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: action.payload,
        },
      });
    },
    closeListingRequest: (state, action) => {
      Object.assign(state, {
        closingListing: action.payload.listingId,
        closingListingError: null,
      });
    },
    closeListingSuccess: (state, action) => {
      Object.assign(state, {
        ...updateListingAttributes(state, action.payload.data),
        closingListing: null,
      });
    },
    closeListingError: (state, action) => {
      console.error(action.payload);
      Object.assign(state, {
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: action.payload,
        },
      });
    },
    addOwnEntities: (state, action) => {
      Object.assign(state, {
        ...merge(state, action.payload)
      });
    },
    addNextEntities: (state, action) => {
      state.ownEntities = Object.assign(state.ownEntities, merge(state, action.payload).ownEntities);
      state.currentPageResultIds.push(...resultIds(action.payload.data));
      state.nextListingsPage += 1;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
