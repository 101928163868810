export const discountOptions = [
  { key: '5', label: '5%' },
  { key: '10', label: '10%' },
  { key: '15', label: '15%' },
  { key: '20', label: '20%' },
  { key: '25', label: '25%' },
  { key: '30', label: '30%' },
  { key: '35', label: '35%' },
  { key: '40', label: '40%' },
  { key: '45', label: '45%' },
  { key: '50', label: '50%' },
  { key: '55', label: '55%' },
  { key: '60', label: '60%' },
  { key: '65', label: '65%' },
  { key: '70', label: '70%' },
  { key: '75', label: '75%' },
  { key: '80', label: '80%' },
  { key: '85', label: '85%' },
  { key: '90', label: '90%' },
];
