import isEmpty from 'lodash/isEmpty';
import { actions as userActions } from 'storage/slices/user';
import { fetchCurrentUser } from '../../storage/slices/user';
import { createUserWithIdp } from '../../util/api';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { createSlice } from '@reduxjs/toolkit';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authInfoRequest: state => {
      return state;
    },
    authInfoSuccess: (state, action) => {
      Object.assign(state, {
        authInfoLoaded: true,
        isAuthenticated: authenticated(action.payload),
        authScopes: action.payload.scopes,
      });
    },
    loginRequest: state => {
      Object.assign(state, {
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      });
    },
    loginSuccess: state => {
      Object.assign(state, {
        loginInProgress: false,
        isAuthenticated: true,
      });
    },
    loginError: (state, action) => {
      Object.assign(state, {
        loginInProgress: false,
        loginError: action.payload,
      });
    },
    logoutRequest: state => {
      Object.assign(state, {
        logoutInProgress: true,
        loginError: null,
        logoutError: null,
      });
    },
    logoutSuccess: state => {
      Object.assign(state, {
        logoutInProgress: false,
        isAuthenticated: false,
        authScopes: [],
      });
    },
    logoutError: (state, action) => {
      Object.assign(state, {
        logoutInProgress: false,
        logoutError: action.payload,
      });
    },
    signupRequest: state => {
      Object.assign(state, {
        signupInProgress: true,
        loginError: null,
        signupError: null,
      });
    },
    signupSuccess: state => {
      state.signupInProgress = false;
    },
    signupError: (state, action) => {
      Object.assign(state, {
        signupInProgress: false,
        signupError: action.payload,
      });
    },
    confirmRequest: state => {
      Object.assign(state, {
        confirmInProgress: true,
        loginError: null,
        confirmError: null,
      });
    },
    confirmSuccess: state => {
      Object.assign(state, {
        confirmInProgress: false,
        isAuthenticated: true,
      });
    },
    confirmError: (state, action) => {
      Object.assign(state, {
        confirmInProgress: false,
        confirmError: action.payload,
      });
    },
  },
});

export const actions = slice.actions;

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(actions.authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(actions.authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(actions.authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(actions.loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(actions.loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => dispatch(actions.loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(actions.logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(actions.logoutSuccess());
      dispatch(userActions.clearCurrentUser());
      log.clearUserId();
      dispatch(actions.logoutRequest());
    })
    .catch(e => dispatch(actions.logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(actions.signupRequest());
  const { email, password, passwordRepeat, termsAndConditionsAccept, firstName, lastName, ...rest } = params;

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName }
    : { email, password, firstName, lastName, protectedData: { ...rest } };

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(actions.signupSuccess()))
    .then(() => dispatch(login(email, password)))
    .catch(e => {
      dispatch(actions.signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(actions.confirmRequest());
  return createUserWithIdp(params)
    .then(res => {
      return dispatch(actions.confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(actions.confirmError(storableError(e)));
    });
};

export default slice.reducer;
