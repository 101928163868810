import { actions } from './reducer';
import { actions as marketplaceDataActions } from 'storage/slices/marketplaceData';
import { fetchCurrentUser } from 'storage/slices/user';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../../util/data';
import { storableError } from '../../../util/errors';

const { UUID } = sdkTypes;

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(actions.queryListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(actions.queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(actions.queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(actions.queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(actions.showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.showUserSuccess());
      return response;
    })
    .catch(e => dispatch(actions.showUserError(storableError(e))));
};

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(actions.setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryUserReviews(userId)),
  ]);
};
