import { createSlice } from '@reduxjs/toolkit';
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  isCancelReservationModalVisible: false,
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  favoritesLists: [],
  favoritesEntities: [],
  favoritesListsImages: null,
  selectedFavoritesList: null,
  cancelledBookingsIds: [],
  isFavoritesDataError: false,
};

const slice = createSlice({
  name: 'manageBookingsPage',
  initialState,
  reducers: {
    toggleModalVisibility: state => {
      Object.assign(state, {
        isCancelReservationModalVisible: !state.isCancelReservationModalVisible,
      });
    },
    fetchOrdersOrSalesRequest: (state) => {
      Object.assign(state, {
        fetchInProgress: true,
        fetchOrdersOrSalesError: null,
      });
    },
    fetchOrdersOrSalesSuccess: (state, action) => {
      const transactions = sortedTransactions(action.payload.data.data);

      Object.assign(state, {
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: action.payload.data.meta,
      });
    },
    fetchOrdersOrSalesError: (state, action) => {
      Object.assign(state, {
        fetchInProgress: false,
        fetchOrdersOrSalesError: action.payload
      });
    },
    fillFavoritesLists: (state, action) => {
      Object.assign(state, {
        favoritesLists: action.payload,
      });
    },
    fillFavoritesEntities: (state, action) => {
      // prevent favorites from duplicating
      if (!state.favoritesEntities.some(fav => fav.data.id.uuid === action.payload.data.id.uuid)) {
        state.favoritesEntities.push(action.payload);
      }
    },
    clearFavoritesEntities: (state, action) => {
      Object.assign(state, {
        favoritesEntities: [],
      });
    },
    fillFavoritesListsImages: (state, action) => {
      Object.assign(state, {
        favoritesListsImages: {
          ...state.favoritesListsImages,
          [action.payload.listId]: [
            ...(state.favoritesListsImages?.[action.payload.listId] || []),
            action.payload.url
          ]
        },
      });
    },
    clearFavoritesListsImages: (state, action) => {
      Object.assign(state, {
        favoritesListsImages: null,
      });
    },
    setSelectedFavoritesList: (state, action) => {
      Object.assign(state, {
        selectedFavoritesList: action.payload,
      });
    },
    setSelectedTransaction: (state, action) => {
      Object.assign(state, {
        selectedTransactionForCancelling: action.payload,
      });
    },
    clearSelectedTransaction: (state, action) => {
      Object.assign(state, {
        selectedTransactionForCancelling: null,
      });
    },
    onErrorOnCancelling: (state) => {
      Object.assign(state, {
        isErrorOnCancelling: true,
      });
    },
    addCancelledBookingId: (state, action) => {
      Object.assign(state, {
        cancelledBookingsIds: [...state.cancelledBookingsIds, action.payload]
      })
    },
    setCancelRequestLoading: (state) => {
      Object.assign(state, {
        isCancelRequestLoading: !state.isCancelRequestLoading,
      });
    },
    setIsFavoritesDataError: (state, action) => {
      Object.assign(state, {
        isFavoritesDataError: action.payload,
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
