import React from 'react';

import CancelReservationForm from "../../forms/CancelReservationForm/CancelReservationForm";
import { FormattedMessage } from "../../util/reactIntl";

import css from './ConfirmCancelReservationModal.module.css';
import { Modal } from "../index";
import { manageDisableScrolling } from "../../storage/slices/ui";
import IconSpinner from 'components/IconSpinner/IconSpinner';

const ConfirmCancelReservationModal = ({
  visible,
  visibilityHandler,
  intl,
  clearSelectedTransaction,
  cancelUpcomingBooking,
  isErrorOnCancelling,
  isCancelRequestLoading,
  isProvider,
}) => {
  const messageOnCancelError = isErrorOnCancelling && (
    <div className={css.errorMessage}>
      <FormattedMessage id="UpcomingBookingPage.onCancellingErrorMessage" />
    </div>
  );

  const loadingIndicator = isCancelRequestLoading && (
    <div className={css.loadingIcon}>
      <IconSpinner/>
    </div>
  );

  return (
    <Modal
      id="UpcomingBookingPage.cancelReservationModal"
      isOpen={visible}
      onClose={visibilityHandler}
      intl={intl}
      onManageDisableScrolling={manageDisableScrolling}
      usePortal
      containerClassName={css.modalContentContainer}
      withCloseButton={false}
      isOpenClassName={css.backgroundBlur}
      scrollLayerClassName={css.scrollLayer}
    >
      <div className={css.modalTitle}>
        <FormattedMessage id="ConfirmCancelReservationModal.title" />
      </div>
      <div className={css.modalHint}>
        <FormattedMessage id="ConfirmCancelReservationModal.hint" />
      </div>
      <CancelReservationForm
        visibilityHandler={visibilityHandler}
        clearSelectedTransaction={clearSelectedTransaction}
        cancelUpcomingBooking={cancelUpcomingBooking}
        isProvider={isProvider}
      />
      {messageOnCancelError}
      {loadingIndicator}
    </Modal>
  );
};

export default ConfirmCancelReservationModal;
