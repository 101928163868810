import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
};

const slice = createSlice({
  name: 'transactionPage',
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      Object.assign(state, {
        ...initialState,
        ...action.payload,
      });
    },
    savePayoutDetailsRequest: (state) => {
      Object.assign(state, {
        payoutDetailsSaveInProgress: true
      })
    },
    savePayoutDetailsError: (state) => {
      Object.assign(state, {
        payoutDetailsSaveInProgress: false
      })
    },
    savePayoutDetailsSuccess: (state) => {
      Object.assign(state, {
        payoutDetailsSaveInProgress: false,
        payoutDetailsSaved: true
      })
    },

  },
});

export const actions = slice.actions;
export default slice.reducer;
