import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
};

const slice = createSlice({
  name: 'listingPage',
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      Object.assign(state, {
        ...initialState, 
        ...action.payload,
      });
    },
    showListingRequest: (state, action) => {
      Object.assign(state, {
        id: action.payload.id, 
        showListingError: null,
      });
    },
    showListingError: (state, action) => {
      state.showListingError = action.payload;
    },
    fetchReviewsRequest: (state) => {
      state.fetchReviewsError = null;
    },
    fetchReviewsSuccess: (state, action) => {
      state.reviews = action.payload;
    },
    fetchReviewsError: (state, action) => {
      state.fetchReviewsError = action.payload;
    },
    fetchTimeSlotsRequest: (state) => {
      state.fetchTimeSlotsError = null;
    },
    fetchTimeSlotsSuccess: (state, action) => {
      state.timeSlots = action.payload;
    },
    fetchTimeSlotsError: (state, action) => {
      state.fetchTimeSlotsError = action.payload;
    },
    fetchLineItemsRequest: (state) => {
      Object.assign(state, {
        fetchLineItemsInProgress: true, 
        fetchLineItemsError: null,
      });
    },
    fetchLineItemsSuccess: (state, action) => {
      Object.assign(state, {
        fetchLineItemsInProgress: false, 
        lineItems: action.payload,
      });
    },
    fetchLineItemsError: (state, action) => {
      Object.assign(state, {
        fetchLineItemsInProgress: false, 
        fetchLineItemsError: action.payload,
      });
    },
    sendEnquiryRequest: (state) => {
      Object.assign(state, {
        sendEnquiryInProgress: true, 
        sendEnquiryError: null,
      });
    },
    sendEnquirySuccess: (state) => {
      state.sendEnquiryInProgress = false;
    },
    sendEnquiryError: (state, action) => {
      Object.assign(state, {
        sendEnquiryInProgress: false, 
        sendEnquiryError: action.payload,
      });
    },
  },
})

export const actions = slice.actions;
export default slice.reducer;
