import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadedFileName: null,
  resumeSendSuccess: false,
  resumeSendError: false,
  captchaSuccess: false,
  resumeSendPending: false,
};

const slice = createSlice({
  name: 'carreersPageSlice',
  initialState,
  reducers: {
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setCaptchaSuccess: (state, action) => {
      state.captchaSuccess = action.payload;
    },
    setEmailSuccess: (state, action) => {
      state.resumeSendSuccess = action.payload;
    },
    setEmailError: (state, action) => {
      state.resumeSendError = action.payload;
    },
    setResumeSendPending: (state, action) => {
      state.resumeSendPending = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
