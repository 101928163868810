import { actions } from './reducer';
import { fetchCurrentUser } from 'storage/slices/user';
import { actions as paymentMethodsActions } from 'storage/slices/paymentMethods';
import { storableError } from '../../../util/errors';
import * as log from '../../../util/log';

export const createStripeSetupIntent = () => (dispatch, getState, sdk) => {
  dispatch(actions.setupIntentRequest());
  return sdk.stripeSetupIntents
    .create()
    .then(response => {
      const setupIntent = response.data.data;
      dispatch(actions.setupIntentSuccess(setupIntent));
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(actions.setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(actions.stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(actions.stripeCustomerSuccess());
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-stripe-customer-failed');
      dispatch(actions.stripeCustomerError(error));
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(paymentMethodsActions.setInitialValues());

  return dispatch(stripeCustomer());
};
