import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import { storableError } from '../../util/errors';
import { fetchCurrentUser } from './user';
import { parse } from '../../util/urlHelpers';
import { AppStorage } from 'typings/store/store';

// ================ Action types ================ //

export const VERIFICATION_REQUEST = 'app/EmailVerification/VERIFICATION_REQUEST';
export const VERIFICATION_SUCCESS = 'app/EmailVerification/VERIFICATION_SUCCESS';
export const VERIFICATION_ERROR = 'app/EmailVerification/VERIFICATION_ERROR';

// ================ Reducer ================ //

const initialState: EmailVerification = {
  isVerified: false,

  // verification
  verificationError: null,
  verificationInProgress: false,
};

export const slice = createSlice({
  name: 'emailVerification',
  initialState,
  reducers: {
    verificationRequest: (state) => {
      return Object.assign(state, {
        verificationInProgress: true,
        verificationError: null,
      });
    },
    verificationSuccess: (state) => {
      return Object.assign(state, {
        verificationInProgress: false,
        isVerified: true
      });
    },
    verificationError: (state, action) => {
      return Object.assign(state, {
        verificationInProgress: false,
        verificationError: action.payload
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;

// ================ Selectors ================ //

export const verificationInProgress = (state: AppStorage) => {
  return state.emailVerification.verificationInProgress;
};

export const verify = (verificationToken: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStorage, sdk: SdkObject) => {
  if (verificationInProgress(getState())) {
    return Promise.reject(new Error('Email verification already in progress'));
  }
  dispatch(actions.verificationRequest());

  // Note that the thunk does not reject when the verification fails, it
  // just dispatches the login error action.
  try {
    await sdk.currentUser
      .verifyEmail({ verificationToken });
    dispatch(actions.verificationSuccess());
    return dispatch(fetchCurrentUser());
  } catch (e) {
    return dispatch(actions.verificationError(storableError(e)));
  }
};

export const loadData = (params: unknown, search: string) => {
  const urlParams: any = parse(search);
  const verificationToken = urlParams.t;
  const token = verificationToken ? `${verificationToken}` : '';
  return verify(token);
};
