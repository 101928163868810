import React from 'react';
import classNames from 'classnames';

import css from './IconEye.module.css';

interface Props {
  rootClassName: string;
  className: string;
}

const IconEyeInvisible = ({
  rootClassName = '',
  className = ''
}: Props) => {
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.9061 14.6251C12.6023 14.6251 13.27 14.3485 13.7623 13.8562C14.2546 13.3639 14.5311 12.6963 14.5311 12.0001C14.5311 11.9232 14.5276 11.847 14.5211 11.7718L11.6779 14.615C11.7531 14.6216 11.829 14.6251 11.9061 14.6251ZM20.5949 3.88038L19.5936 2.88007C19.5585 2.84493 19.5108 2.8252 19.4611 2.8252C19.4114 2.8252 19.3637 2.84493 19.3286 2.88007L16.7664 5.44296C15.3529 4.72062 13.7641 4.35944 11.9999 4.35944C7.4952 4.35944 4.13426 6.70554 1.91708 11.3977C1.82796 11.5864 1.78174 11.7925 1.78174 12.0012C1.78174 12.2099 1.82796 12.416 1.91708 12.6048C2.80302 14.4708 3.86981 15.9666 5.11747 17.0919L2.63802 19.5704C2.60288 19.6055 2.58314 19.6532 2.58314 19.7029C2.58314 19.7526 2.60288 19.8003 2.63802 19.8355L3.63856 20.836C3.67372 20.8711 3.72139 20.8909 3.7711 20.8909C3.82081 20.8909 3.86848 20.8711 3.90364 20.836L20.5949 4.14569C20.6123 4.12828 20.6261 4.1076 20.6356 4.08484C20.645 4.06208 20.6499 4.03768 20.6499 4.01304C20.6499 3.9884 20.645 3.964 20.6356 3.94124C20.6261 3.91847 20.6123 3.8978 20.5949 3.88038ZM7.78114 12.0001C7.78107 11.2876 7.96555 10.5872 8.31661 9.96723C8.66766 9.34723 9.17332 8.82873 9.78432 8.46225C10.3953 8.09576 11.0908 7.89378 11.8031 7.87598C12.5154 7.85818 13.2201 8.02518 13.8486 8.36069L12.7091 9.50022C12.2495 9.35307 11.7583 9.33535 11.2894 9.449C10.8204 9.56265 10.3918 9.80329 10.0506 10.1445C9.70935 10.4857 9.46872 10.9143 9.35507 11.3833C9.24142 11.8523 9.25914 12.3435 9.40629 12.803L8.26676 13.9426C7.94698 13.3451 7.78014 12.6777 7.78114 12.0001Z" fill="inherit"/>
      <path d="M22.0828 11.3954C21.2578 9.65791 20.2759 8.24111 19.137 7.14502L15.7587 10.5235C16.0434 11.2679 16.1066 12.0789 15.9404 12.8584C15.7743 13.6379 15.3859 14.3526 14.8223 14.9162C14.2588 15.4798 13.5441 15.8681 12.7645 16.0343C11.985 16.2004 11.1741 16.1373 10.4297 15.8525L7.56421 18.718C8.89359 19.3331 10.3722 19.6407 12 19.6407C16.5047 19.6407 19.8656 17.2946 22.0828 12.6024C22.172 12.4137 22.2182 12.2076 22.2182 11.9989C22.2182 11.7902 22.172 11.5841 22.0828 11.3954Z" fill="inherit"/>
    </svg>
  );
};

export default IconEyeInvisible;
