import React from 'react';
import classNames from 'classnames';

import css from './IconTrash.module.css';

interface Props {
  rootClassName: string;
  className: string;
}

const IconTrash = ({
  rootClassName = '',
  className = ''
}: Props) => {
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26 6H32V8H30V26L28 28H14L12 26V8H10V6H16V4C16 3.46957 16.2107 2.96086 16.5858 2.58579C16.9609 2.21071 17.4696 2 18 2H24C24.5304 2 25.0391 2.21071 25.4142 2.58579C25.7893 2.96086 26 3.46957 26 4V6ZM24 4H18V6H24V4ZM14 26H28V8H14V26ZM18 10H16V24H18V10ZM20 10H22V24H20V10ZM24 10H26V24H24V10Z"
        fill="inherit"
      />
    </svg>
  );
};

export default IconTrash;
