import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { actions } from './reducer';
import config from 'config';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../../util/errors';
import {
  txIsEnquired,
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
} from '../../../util/transaction';
import { transactionLineItems } from '../../../util/api';
import * as log from '../../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../../util/data';
import { actions as marketplaceDataActions } from 'storage/slices/marketplaceData';
import { fetchCurrentUserNotifications } from 'storage/slices/user';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

export const acceptOrDeclineInProgress = (state: any) => {
  return state.transactionPage.acceptInProgress || state.transactionPage.declineInProgress;
};

// ================ Thunks ================ //

const listingRelationship = (txResponse: any) => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id: any, txRole: any) => (
  dispatch: any,
  getState: any,
  sdk: any
) => {
  dispatch(actions.fetchTransactionRequest());
  let txResponse: any = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then((response: any) => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then((response: any) => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(txResponse));
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch((e: Error) => {
      dispatch(actions.fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const acceptSale = (id: any) => (dispatch: any, getState: any, sdk: any) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(actions.acceptSaleRequest());

  return sdk.transactions.transition({ id, transition: TRANSITION_ACCEPT, params: {} }, { expand: true })
    .then((response: any) => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.acceptSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch((e: Error) => {
      dispatch(actions.acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineSale = (id: any) => (dispatch: any, getState: any, sdk: any) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(actions.declineSaleRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE, params: {} }, { expand: true })
    .then((response: any) => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.declineSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch((e: Error) => {
      dispatch(actions.declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

const fetchMessages = (txId: any, page: any) => (dispatch: any, getState: any, sdk: any) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(actions.fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then((response: any) => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination: any = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().transactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(actions.fetchMessagesSuccess({ messages, pagination }));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch((e: Error) => {
      dispatch(actions.fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = (txId: any) => (dispatch: any, getState: any, sdk: any) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.transactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId: any, message: any) => (
  dispatch: any,
  getState: any,
  sdk: any
) => {
  dispatch(actions.sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then((response: any) => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(actions.sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(actions.sendMessageSuccess()));
    })
    .catch((e: Error) => {
      dispatch(actions.sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id: any, params: any, role: any, dispatch: any, sdk: any) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then((response: any) => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.sendReviewSuccess);
      return response;
    })
    .catch((e: Error) => {
      dispatch(actions.sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id: any, params: any, role: any, dispatch: any, sdk: any) => {
  const transition = getReview1Transition(role === CUSTOMER);
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then((response: any) => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(actions.sendReviewSuccess);
      return response;
    })
    .catch((e: Error) => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(actions.sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role: any, tx: any, reviewRating: any, reviewContent: any) => (
  dispatch: any,
  getState: any,
  sdk: any
) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

  dispatch(actions.sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
};

const isNonEmpty = (value: any) => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = (params: any) => (dispatch: any, getState: any, sdk: any) => {
  return sdk.timeslots.query(params).then((response: any) => {
    return denormalisedResponseEntities(response);
  });
};

const fetchTimeSlots = (listingId: any) => (dispatch: any, getState: any, sdk: any) => {
  dispatch(actions.fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then((timeSlots: any) => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then((secondBatch: any) => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(actions.fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(actions.fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch((e: Error) => {
      dispatch(actions.fetchTimeSlotsError(storableError(e)));
    });
};

export const fetchNextTransitions = (id: any) => (dispatch: any, getState: any, sdk: any) => {
  dispatch(actions.fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then((res: any) => {
      dispatch(actions.fetchTransitionsSuccess(res.data.data));
    })
    .catch((e: Error) => {
      dispatch(actions.fetchTransitionsError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }: any) => (
  dispatch: any
) => {
  dispatch(actions.fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(actions.fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(actions.fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = (params: any) => (dispatch: any, getState: any) => {
  const txId = new UUID(params.id);
  const state = getState().transactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(actions.setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};
