import React from 'react';
import classNames from 'classnames';
import { useIntl, IntlShape, FormattedMessage } from 'react-intl';

import { IconCheckmark, Modal } from '../../components';

import css from './DeclineReasonsModal.module.css';

const getDeclineReasons = (intl: IntlShape) => [
  {
    key: 1,
    value: intl.formatMessage({ id: 'InboxPage.datesBlocked' }),
    isChecked: false,
  },
  {
    key: 2,
    value: intl.formatMessage({ id: 'InboxPage.roomUnavailable' }),
    isChecked: false,
  },
  {
    key: 3,
    value: intl.formatMessage({ id: 'InboxPage.underMaintenance' }),
    isChecked: false,
  },
];

interface DeclineReasonsModalProps {
  className: string;
  rootClassName: string;
  id: string;
  isOpen: boolean;
  sendMessageInProgress: boolean;
  fetchMessagesInProgress: boolean;
  onCloseModal: () => void;
  onManageDisableScrolling: () => void;
  onDeclineReasonsSubmit: (message: string) => void;
}

const DeclineReasonsModal = (props: DeclineReasonsModalProps) => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    sendMessageInProgress,
    fetchMessagesInProgress,
    onCloseModal,
    onManageDisableScrolling,
    onDeclineReasonsSubmit
  } = props;
  const intl = useIntl();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const classes = classNames(rootClassName || css.root, className);

  const otherDefault = {
    isChecked: false,
    text: intl.formatMessage({ id: 'InboxPage.other' }),
    value: '',
  };
  const [declineReasonsState, setDeclineReasonsState] = React.useState({
    reasons: getDeclineReasons(intl),
    other: otherDefault,
    reasonValue: ''
  });
  const { reasons, other, reasonValue } = declineReasonsState;

  const onReasonClickhandler = (reasonKey: number) => {
    const choosedReason = declineReasonsState.reasons.find((reason) => reasonKey === reason.key);

    if (choosedReason?.value) {
      return setDeclineReasonsState(prev => ({
        ...prev,
        reasonValue: choosedReason.value,
        other: otherDefault,
      }));
    }
  };

  const onOtherLabelClickHandler = () => {
    return setDeclineReasonsState({
      reasons: getDeclineReasons(intl),
      other: { ...otherDefault, isChecked: true },
      reasonValue: '',
    })
  };

  const onCancelClickHandler = () => {
    setDeclineReasonsState({
      reasons: getDeclineReasons(intl),
      other: otherDefault,
      reasonValue: ''
    });
    return onCloseModal();
  };

  const onSendClickHandler = () => {
    let value;
    if (other.isChecked && !reasonValue) {
      value = inputRef.current?.value;
    }

    return onDeclineReasonsSubmit(value || reasonValue);
  };

  const getIsReasonSelected = (reasonKey: number) => {
    const сurrentReason = reasons.find((reason) => reasonKey === reason.key);

    return сurrentReason?.value === reasonValue;
  };

  const getDeclineReasonsList = () => (
    reasons.map(({ key, value }) => {
      const isReasonSelected = getIsReasonSelected(key);

      return (
        <li
          key={key}
          onClick={() => onReasonClickhandler(key)}
          className={classNames(css.reasonListItem, {
            [css.reasonListItemActive]: isReasonSelected,
          })}
        >
          {isReasonSelected && <IconCheckmark className={css.checkmarkIcon} />}
          {value}
        </li>
      )
    })
  );

  const isOtherSelected = other.isChecked;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      scrollLayerClassName={css.scroll}
      contentClassName={css.modalContent}
      isOpenClassName={css.isOpenClass}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      withCloseButton={false}
    >
      <span className={css.modalTitle}>
        <FormattedMessage id="InboxPage.indicateDeclineReason" />
      </span>
      <div className={css.reasonsListWrapper}>
        <ul className={css.reasonsListContent}>
          {getDeclineReasonsList()}
        </ul>
        <span
          className={classNames(css.otherReasonTitle, {
            [css.reasonListItemActive]: isOtherSelected,
          })}
          onClick={onOtherLabelClickHandler}
        >
          {other.text}
        </span>
        <input
          type='text'
          className={css.otherInput}
          ref={inputRef}
          disabled={!isOtherSelected}
        />
      </div>
      <div className={css.modalButtonsWrapper}>
        <button
          className={css.modalButton}
          onClick={onCancelClickHandler}
        >
          <FormattedMessage id="InboxPage.cancel" />
        </button>
        <button
          className={css.modalButton}
          onClick={onSendClickHandler}
          disabled={sendMessageInProgress || fetchMessagesInProgress}
        >
          <FormattedMessage id="InboxPage.send" />
        </button>
      </div>
    </Modal>
  );
};


export default DeclineReasonsModal;
