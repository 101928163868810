import { actions } from './reducer';
import { storableError } from '../../../util/errors';

export const changePassword = params => (dispatch, getState, sdk) => {
  dispatch(actions.changePasswordRequest());
  const { newPassword, currentPassword } = params;

  return sdk.currentUser
    .changePassword({ newPassword, currentPassword })
    .then(() => dispatch(actions.changePasswordSuccess()))
    .catch(e => {
      dispatch(actions.changePasswordError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const resetPassword = email => (dispatch, getState, sdk) => {
  dispatch(actions.resetPasswordRequest());
  return sdk.passwordReset
    .request({ email })
    .then(() => dispatch(actions.resetPasswordSuccess()))
    .catch(e => dispatch(actions.resetPasswordError(storableError(e))));
};
