import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SITE_LANGUAGE } from '../../../typings/enums';
import { UserSiteSettingsState } from '../../../typings/store/userSiteSettings';

const initialState: UserSiteSettingsState = {
  userLanguage: SITE_LANGUAGE.en,
  isLoading: false,
};

const slice = createSlice({
  name: 'userSiteSettings',
  initialState,
  reducers: {
    changeSiteLanguage: (state: UserSiteSettingsState, action: PayloadAction<SITE_LANGUAGE>) => {
      state.userLanguage = action.payload;
    },
    loadingStart: (state: UserSiteSettingsState) => {
      state.isLoading = true;
    },
    loadingEnd: (state: UserSiteSettingsState) => {
      state.isLoading = false;
    },
  },
});

export const actions = slice.actions;

export default slice.reducer;
