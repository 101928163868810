import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  changePasswordError: null,
  changePasswordInProgress: false,
  passwordChanged: false,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const slice = createSlice({
  name: 'passwordChangePage',
  initialState,
  reducers: {
    changePasswordRequest: (state) => {
      Object.assign(state, {
        changePasswordInProgress: true,
        changePasswordError: null,
        passwordChanged: false,
      });
    },
    changePasswordSuccess: (state) => {
      Object.assign(state, {
        changePasswordInProgress: false, 
        passwordChanged: true,
      });
    },
    changePasswordError: (state, action) => {
      Object.assign(state, {
        changePasswordInProgress: false, 
        changePasswordError: action.payload,
      });
    },
    changePasswordClear: (state) => {
      Object.assign(state, {...initialState})
    },
    resetPasswordRequest: (state, action) => {
      Object.assign(state, {
        resetPasswordInProgress: true, 
        resetPasswordError: null,
      })
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordInProgress = false;
    },
    resetPasswordError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
      Object.assign(state, {
        resetPasswordInProgress: false, 
        resetPasswordError: action.payload,
      })
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
