import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const slice = createSlice({
  name: 'contactDetailsPage',
  initialState,
  reducers: {
    saveContactDetailsRequest: (state) => {
      Object.assign(state, {
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      });
    },
    saveContactDetailsSuccess: (state) => {
      Object.assign(state, {
        saveContactDetailsInProgress: false, 
        contactDetailsChanged: true,
      });
    },
    saveEmailError: (state, action) => {
      Object.assign(state, {
        saveContactDetailsInProgress: false, 
        saveEmailError: action.payload,
      });
    },
    savePhoneNumberError: (state, action) => {
      Object.assign(state, {
        saveContactDetailsInProgress: false, 
        savePhoneNumberError: action.payload 
      });
    },
    saveContactDetailsClear: (state) => {
      Object.assign(state, {
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      });
    },
    resetPasswordRequest: (state) => {
      Object.assign(state, {
        resetPasswordInProgress: true, 
        resetPasswordError: null,
      });
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordInProgress = false;
    },
    resetPasswordError: (state, action) => {
      Object.assign(state, {
        resetPasswordInProgress: false, 
        resetPasswordError: action.payload,
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
