import { combineReducers } from 'redux';


import auth from './auth';
import emailVerification from './emailVerification';
import locationFilter from './locationFilter';
import routing from './routing';
import ui from './ui';
import marketplaceData from './marketplaceData';
import paymentMethods from './paymentMethods';
import stripe from './stripe';
import stripeConnectAccount from './stripeConnectAccount';
import user from './user';
import userSiteSettings from './userSiteSettings';
import * as containersSlices from '../../containers/reducers';

const reducer = () => combineReducers({
  ...containersSlices,
  auth,
  emailVerification,
  locationFilter,
  routing,
  ui,
  marketplaceData,
  paymentMethods,
  stripe,
  stripeConnectAccount,
  user,
  userSiteSettings,
});

export default reducer;
