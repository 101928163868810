import React from 'react';
import ReactDOMServer from 'react-dom/server';
import 'react-dates/initialize';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { IntlProvider } from './util/reactIntl';
import configureStore from './storage/configureStore';
import routeConfiguration from './routeConfiguration';
import Routes from './Routes';
import config from './config';
import { AppStorage } from 'typings/store/store';
import { SITE_LANGUAGE } from 'typings/enums';
import { actions as userSettingsActions } from './storage/slices/userSiteSettings';

import messageEn from './translations/en.json';
import messageFr from './translations/fr.json';
import messageEs from './translations/es.json';

interface LanguageProviderProps {
  children: React.ReactNode;
}

const isTestEnv = process.env.NODE_ENV === 'test';

const setupLocale = (language: string) => {
  if (isTestEnv) {
    config.locale = 'en';
    return;
  }

  moment.locale(language);
};

const getMessages = (locale: SITE_LANGUAGE) => {
  switch (locale) {
    case SITE_LANGUAGE.fr:
      return messageFr
    case SITE_LANGUAGE.es:
      return messageEs;
    default:
      return messageEn;
  }
};

const LanguageProvider = (props: LanguageProviderProps) => {
  const { children } = props;
  const language = useSelector((state: AppStorage) => state.userSiteSettings.userLanguage);
  const dispatch = useDispatch();
  //get current user language for all pages
  dispatch(userSettingsActions.getUserLanguage());
  setupLocale(language);

  return (
    <IntlProvider locale={language} messages={getMessages(language)}>
      {children}
    </IntlProvider>
  )
}

export const ClientApp = (props: {store: any}) => {
  const { store } = props;

  return (
    <Provider store={store}>
      <LanguageProvider>
        <HelmetProvider>
          <BrowserRouter>
            <Routes routes={routeConfiguration()} />
          </BrowserRouter>
        </HelmetProvider>
      </LanguageProvider>
    </Provider>
  );
};

export const ServerApp = (props: {url: string, context: any, helmetContext: any, store: any }) => {
  const { url, context, helmetContext, store } = props;
  HelmetProvider.canUseDOM = false;
  return (
    <Provider store={store}>
      <LanguageProvider>
        <HelmetProvider context={helmetContext}>
          <StaticRouter location={url} context={context}>
            <Routes routes={routeConfiguration()} />
          </StaticRouter>
        </HelmetProvider>
      </LanguageProvider>
    </Provider>
  );
};

export const renderApp = (url: string, serverContext: any, preloadedState: {[key: string]: string}, collectChunks: any) => {
  const store = configureStore(preloadedState);

  const helmetContext = { helmet: '' };

  const WithChunks = collectChunks(
    <ServerApp url={url} context={serverContext} helmetContext={helmetContext} store={store} />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
