import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.module.css';

const IconLocation = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' stroke='#E1E1E1'>
      <path
        d='M24.0002 15C21.0002 22 16.0002 30 16.0002 30C16.0002 30 11.0002 22 8.00024 15C5.00024 8 10.0002 2 16.0002 2C22.0002 2 27.0002 8 24.0002 15Z'
        stroke='#E1E1E1' fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />

      <path
        d='M16 15C18.2091 15 20 13.2091 20 11C20 8.79086 18.2091 7 16 7C13.7909 7 12 8.79086 12 11C12 13.2091 13.7909 15 16 15Z'
        stroke='#E1E1E1' fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

IconLocation.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconLocation.propTypes = { className: string, rootClassName: string };

export default IconLocation;
