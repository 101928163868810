import { actions as inboxPageActions } from './reducer';
import { storableError } from '../../../util/errors';
import { parse } from '../../../util/urlHelpers';
import {
  TRANSITIONS,
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_COMPLETE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_CANCEL_PERIOD,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
} from '../../../util/transaction';
import { actions as marketplaceDataActions } from 'storage/slices/marketplaceData';

const INBOX_PAGE_SIZE = 6;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, transitionState } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const transitionFilterValues = {
    'pending-confirmation': [TRANSITION_CONFIRM_PAYMENT],
    'confirmed-reservation': [
      TRANSITION_ACCEPT,
      TRANSITION_EXPIRE_CANCEL_PERIOD,
    ],
    'declined': [
      TRANSITION_DECLINE,
      TRANSITION_EXPIRE,
      TRANSITION_CANCEL,
      TRANSITION_CANCEL_BY_CUSTOMER,
      TRANSITION_CANCEL_BY_PROVIDER,
    ],
    'completed': [
      TRANSITION_COMPLETE,
      TRANSITION_REVIEW_1_BY_CUSTOMER,
      TRANSITION_REVIEW_2_BY_CUSTOMER,
      TRANSITION_REVIEW_1_BY_PROVIDER,
      TRANSITION_REVIEW_2_BY_PROVIDER,
      TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
      TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
      TRANSITION_EXPIRE_REVIEW_PERIOD,
    ],
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const lastTransitions = transitionFilterValues[transitionState] || TRANSITIONS;

  dispatch(inboxPageActions.fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: lastTransitions,
    include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      dispatch(inboxPageActions.fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(inboxPageActions.fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
