import React, { ReactChild } from 'react';

interface ExternalLinkProps {
  key: string; 
  href: string; 
  className: string; 
  title: string; 
  children: ReactChild
}

const ExternalLink = (props: ExternalLinkProps) => {
  const { children, ...rest } = props;
  return (
    <a {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default ExternalLink;
