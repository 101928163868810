/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const amenities = [
  { key: "Hairdryer", label: "Hairdryer" },
  { key: "Hot water", label: "Hot water" },
  { key: "Shower gel", label: "Shower gel" },
  { key: "Washer", label: "Washer" },
  { key: "Dryer", label: "Dryer" },
  {
    key: "Towels, bed sheets, soap, and toilet paper",
    label: "Towels, bed sheets, soap, and toilet paper",
  },
  { key: "Hangers", label: "Hangers" },
  { key: "Bed linens", label: "Bed linens" },
  { key: "Extra pillows and blankets", label: "Extra pillows and blankets" },
  { key: "Iron", label: "Iron" },
  { key: "Suitable for events", label: "Suitable for events" },
  { key: "Heating and cooling", label: "Heating and cooling" },
  { key: "Portable fans", label: "Portable fans" },
  { key: "Heating", label: "Heating" },
  { key: "Smoke alarm", label: "Smoke alarm" },
  { key: "Carbon monoxide alarm", label: "Carbon monoxide alarm" },
  { key: "Fire extinguisher", label: "Fire extinguisher" },
  { key: "First aid kit", label: "First aid kit" },
  { key: "Wifi", label: "Wifi" },
  { key: "Dedicated workspace", label: "Dedicated workspace" },
  { key: "Pocket wifi", label: "Pocket wifi" },
  { key: "Kitchen and dining", label: "Kitchen and dining" },
  { key: "Kitchen", label: "Kitchen" },
  {
    key: "Space where guests can cook their own meals",
    label: "Space where guests can cook their own meals",
  },
  { key: "Refrigerator", label: "Refrigerator" },
  { key: "Microwave", label: "Microwave" },
  { key: "Cooking basics", label: "Cooking basics" },
  {
    key: "Pots and pans, oil, salt, and pepper",
    label: "Pots and pans, oil, salt, and pepper",
  },
  { key: "Dishes and silverware", label: "Dishes and silverware" },
  { key: "Mini fridge", label: "Mini fridge" },
  { key: "Freezer", label: "Freezer" },
  { key: "Stove", label: "Stove" },
  { key: "Oven", label: "Oven" },
  { key: "Coffee maker", label: "Coffee maker" },
  { key: "Barbecue utensils", label: "Barbecue utensils" },
  { key: "Private entrance", label: "Private entrance" },
  {
    key: "Separate street or building entrance",
    label: "Separate street or building entrance",
  },
  { key: "Laundromat nearby", label: "Laundromat nearby" },
  { key: "Patio or balcony", label: "Patio or balcony" },
  { key: "Backyard", label: "Backyard" },
  { key: "BBQ grill", label: "BBQ grill" },
  { key: "Parking and facilities", label: "Parking and facilities" },
  { key: "Free parking on premises", label: "Free parking on premises" },
  { key: "EV charger", label: "EV charger" },
  { key: "Pets allowed", label: "Pets allowed" },
  {
    key: "Assistance animals are always allowed",
    label: "Assistance animals are always allowed",
  },
  { key: "Luggage dropoff allowed", label: "Luggage dropoff allowed" },
  { key: "Breakfast", label: "Breakfast" },
  { key: "Breakfast is provided", label: "Breakfast is provided" },
  { key: "Long term stays allowed", label: "Long term stays allowed" },
  {
    key: "Allow staying for 28 days or more",
    label: "Allow staying for 28 days or more",
  },
  { key: "Cleaning before checkout", label: "Cleaning before checkout" },
  { key: "Laundry service", label: "Laundry service" },
  { key: "Concierge service", label: "Concierge service" },
  { key: "Valet Parking", label: "Valet Parking" },
  { key: "Garden View", label: "Garden View" },
  { key: "Ocean View", label: "Ocean View" },
  { key: "Mountain View", label: "Mountain View" },
  { key: "Beachfront", label: "Beachfront" },
  { key: "Lakefront", label: "Lakefront" },
  { key: "Free Wifi", label: "Free Wifi" },
  { key: "Adults only", label: "Adults only" },
  { key: "Airport Shuttle", label: "Airport Shuttle" },
  { key: "Bar or Lounge", label: "Bar or Lounge" },
  { key: "Beach Access", label: "Beach Access" },
  { key: "Bicycle Rental / Available", label: "Bicycle Rental / Available" },
  { key: "Cable TV", label: "Cable TV" },
  { key: "Conference room", label: "Conference room" },
  {
    key: "Electric Vehicle Charging Station",
    label: "Electric Vehicle Charging Station",
  },
  { key: "Game room", label: "Game room" },
  { key: "Golf Course", label: "Golf Course" },
  { key: "Hot Tub", label: "Hot Tub" },
  { key: "Infinity pool", label: "Infinity pool" },
  { key: "Kitchenette", label: "Kitchenette" },
  { key: "Restaurant", label: "Restaurant" },
  { key: "Rooftop terrace", label: "Rooftop terrace" },
  { key: "Spa", label: "Spa" },
  { key: "Tennis Court", label: "Tennis Court" },
  {
    key: "Watersport equipment rentals",
    label: "Watersport equipment rentals",
  },
  { key: "Yoga room", label: "Yoga room" },
];

export const bookingCancellationReasonsCustomer = [
  {
    id: "bookingCancellationDisaster",
    name: "bookingCancellationReasons",
    value: "naturalDisaster",
    label: "Natural disaster",
  },
  {
    id: "bookingCancellationMedical",
    name: "bookingCancellationReasons",
    value: "medicalEmergency",
    label: "Medical emergency",
  },
  {
    id: "bookingCancellationDeclared",
    name: "bookingCancellationReasons",
    value: "declaredEmergency",
    label: "Declared emergency",
  },
  {
    id: "bookingCancellationRestrictions",
    name: "bookingCancellationReasons",
    value: "travelRestrictions",
    label: "Travel restrictions",
  },
  {
    id: "bookingCancellationOther",
    name: "bookingCancellationReasons",
    value: "other",
    label: "Other",
  },
];

export const bookingCancellationReasonsProvider = [
  {
    id: "bookingCancellationMaintenance",
    name: "bookingCancellationReasons",
    value: "maintenanceRepairs",
    label: "Maintenance & Repairs",
  },
  {
    id: "bookingCancellationDisaster",
    name: "bookingCancellationReasons",
    value: "naturalDisaster",
    label: "Natural disaster",
  },
  {
    id: "bookingCancellationWar",
    name: "bookingCancellationReasons",
    value: "warCivilUnrest",
    label: "War / Civil Unrest",
  },
  {
    id: "bookingCancellationDates",
    name: "bookingCancellationReasons",
    value: "datesNotAvailable",
    label: "Chosen dates not available",
  },
  {
    id: "bookingCancellationOther",
    name: "bookingCancellationReasons",
    value: "other",
    label: "Other",
  },
];

export const filters = [
  {
    id: "dates",
    label: "Dates",
    type: "BookingDateRangeFilter",
    group: "primary",
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ["dates"],
    config: {},
  },
  {
    id: "price",
    label: "Price",
    type: "PriceFilter",
    group: "primary",
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ["price"],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 10000,
      step: 25,
    },
  },
  {
    id: "keyword",
    label: "Keyword",
    type: "KeywordFilter",
    group: "primary",
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ["keywords"],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: "category",
    label: "Category",
    type: "SelectSingleFilter",
    group: "secondary",
    queryParamNames: ["pub_category"],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: "studio", label: "Studio" },
        { key: "condo", label: "Condo" },
        { key: "penthouse", label: "Penthouse" },
        { key: "guesthouse", label: "Guesthouse" },
        { key: "hostel", label: "Hostel" },
        { key: "house", label: "Whole House" },
        { key: "cabin", label: "Cabin" },
        { key: "apartment", label: "Apartment" },
        { key: "privateKitchen", label: "Private Room with shared kitchen" },
        { key: "privateNoKitchen", label: "Private Room without shared kitchen" },
        { key: "privateBathroom", label: "Private room with shared bathroom" },
        { key: "privateNoBathroom", label: "Private room without shared bathroom" },
      ],
    },
  },
  {
    id: "amenities",
    label: "Amenities",
    type: "SelectMultipleFilter",
    group: "secondary",
    queryParamNames: ["pub_amenities"],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: "has_all",

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: amenities,
    },
  },
  {
    id: 'isDiscountActive',
    label: 'Discount',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_isDiscountActive'],
    config: {
      options: [
        { key: 'true', label: 'Applied' },
        { key: 'false', label: 'Not Applied' },
      ],
    }
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: "sort",

  // Internal key for the relevance option, see notes below.
  relevanceKey: "relevance",

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ["keyword"],

  options: [
    { key: "createdAt", label: "Newest" },
    { key: "-createdAt", label: "Oldest" },
    { key: "-price", label: "Lowest price" },
    { key: "price", label: "Highest price" },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: "relevance",
      label: "Relevance",
      longLabel: "Relevance (Keyword search)",
    },
  ],
};
