import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInfo.module.css';

const IconInfo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0ZM16 29.2857C8.66429 29.2857 2.71429 23.3357 2.71429 16C2.71429 8.66429 8.66429 2.71429 16 2.71429C23.3357 2.71429 29.2857 8.66429 29.2857 16C29.2857 23.3357 23.3357 29.2857 16 29.2857Z" fill="#4A4A4A"/>
      <path d="M14.5 10.5C14.5 10.8978 14.658 11.2794 14.9393 11.5607C15.2206 11.842 15.6022 12 16 12C16.3978 12 16.7794 11.842 17.0607 11.5607C17.342 11.2794 17.5 10.8978 17.5 10.5C17.5 10.1022 17.342 9.72064 17.0607 9.43934C16.7794 9.15804 16.3978 9 16 9C15.6022 9 15.2206 9.15804 14.9393 9.43934C14.658 9.72064 14.5 10.1022 14.5 10.5ZM16.75 14H15.25C15.1125 14 15 14.1125 15 14.25V22.75C15 22.8875 15.1125 23 15.25 23H16.75C16.8875 23 17 22.8875 17 22.75V14.25C17 14.1125 16.8875 14 16.75 14Z" fill="#4A4A4A"/>
    </svg>
  );
};

IconInfo.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconInfo.propTypes = { rootClassName: string, className: string };

export default IconInfo;
