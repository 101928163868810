import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { ensureAvailabilityException } from '../../../util/data';
import { isSameDate, monthIdStringInUTC } from '../../../util/dates';

// A helper function to filter away exception that matches start and end timestamps
const removeException = (exception: ExceptionObject, calendar: EditListingPage['availabilityCalendar']) => {
  const availabilityException = ensureAvailabilityException(exception.availabilityException);
  const { start, end } = availabilityException.attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId: any = monthIdStringInUTC(start);
  const monthData = calendar[monthId] || { exceptions: [{availabilityException: ''}] };

  const exceptions = monthData.exceptions.filter((e: any) => {
    const anException = ensureAvailabilityException(e.availabilityException);
    const exceptionStart = anException.attributes.start;
    const exceptionEnd = anException.attributes.end;

    return !(isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end));
  });

  return {
    ...calendar,
    [monthId]: { ...monthData, exceptions },
  };
};
// A helper function to add a new exception and remove previous one if there's a matching exception
const addException = (exception: ExceptionObject, calendar: EditListingPage['availabilityCalendar']) => {
  const { start } = ensureAvailabilityException(exception.availabilityException).attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  // TODO: API doesn't support "availability_exceptions/update" yet
  // So, when user wants to create an exception we need to ensure
  // that possible existing exception is removed first.
  const cleanCalendar: EditListingPage['availabilityCalendar'] = removeException(exception, calendar);
  const monthData = cleanCalendar[monthId] || { exceptions: [] };

  return {
    ...cleanCalendar,
    [monthId]: { ...monthData, exceptions: [...monthData.exceptions, exception] },
  };
};

// A helper function to update exception that matches start and end timestamps
const updateException = (exception: ExceptionObject, calendar: EditListingPage['availabilityCalendar']) => {
  const newAvailabilityException = ensureAvailabilityException(exception.availabilityException);
  const { start, end } = newAvailabilityException.attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId: any = monthIdStringInUTC(start);
  const monthData = calendar[monthId] || { exceptions: [] };

  const exceptions = monthData.exceptions.map((e: any) => {
    const availabilityException = ensureAvailabilityException(e.availabilityException);
    const exceptionStart = availabilityException.attributes.start;
    const exceptionEnd = availabilityException.attributes.end;

    return isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end) ? exception : e;
  });

  return {
    ...calendar,
    [monthId]: { ...monthData, exceptions },
  };
};

// Update calendar data of given month
const updateCalendarMonth = (state: EditListingPage, monthId: string, data: Partial<AvailabilityCalendarObject>) => {
  // Ensure that every month has array for bookings and exceptions
  const defaultMonthData = { bookings: [], exceptions: [] };
  return {
    ...state,
    availabilityCalendar: {
      ...state.availabilityCalendar,
      [monthId]: {
        ...defaultMonthData,
        ...state.availabilityCalendar[monthId],
        ...data,
      },
    },
  };
};

const initialState: EditListingPage = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  availabilityCalendar: {
    // '2018-12': {
    //   bookings: [],
    //   exceptions: [],
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: false,
    //   fetchBookingsError: null,
    //   fetchBookingsInProgress: false,
    // },
  },
  images: {},
  imageOrder: [],
  removedImageIds: [],
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
};

const slice = createSlice({
  name: 'editListingPage',
  initialState,
  reducers: {
    markTabUpdated: (state, action) => {
      Object.assign(state, state.updatedTab = action.payload);
    },
    clearUpdatedTab: (state) => {
      Object.assign(state, { updatedTab: null, updateListingError: null });
    },
    createListingDraftRequest: (state) => {
      Object.assign(state, {
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      });
    },

    createListingDraftSuccess: (state, action) => {
      Object.assign(state, {
        createListingDraftInProgress: false,
        submittedListingId: action.payload.data.id,
        listingDraft: action.payload.data,
      });
    },
    createListingDraftError:(state, action) => {
      Object.assign(state, {
        createListingDraftInProgress: false,
        createListingDraftError: action.payload,
      });
    },

    publishListingRequest: (state, action) => {
      Object.assign(state, {
        publishingListing: action.payload.listingId,
        publishListingError: null,
      });
    },
    publishListingSuccess: (state) => {
      Object.assign(state, {
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      });
    },
    publishListingError: (state, action) => {
      console.error(action.payload);
      Object.assign(state, {
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: action.payload,
        },
      });
    },
    updateListingRequest: (state) => {
      Object.assign(state, { updateInProgress: true, updateListingError: null });
    },
    updateListingSuccess: (state) => {
      Object.assign(state, { updateInProgress: false });
    },
    updateListingError: (state, action) => {
      Object.assign(state, { updateInProgress: false, updateListingError: action.payload });
    },

    showListingsRequest: (state, action) => {
      Object.assign(state, { showListingsError: null });
    },
    showListingsSuccess: (state, action) => {
      Object.assign(state, { ...initialState, availabilityCalendar: { ...state.availabilityCalendar } });
    },

    showListingsError: (state, action) => {
      console.error(action.payload);
      Object.assign(state, { showListingsError: action.payload, redirectToListing: false });
    },

    fetchBookingsRequest: (state, action) => {
      const updatedMonths = updateCalendarMonth(state, action.payload.monthId, {
        fetchBookingsError: null,
        fetchBookingsInProgress: true,
      });
      Object.assign(state, updatedMonths);
    },
    fetchBookingsSuccess: (state, action) => {
      const updatedCalendarMonth = updateCalendarMonth(state, action.payload.data.monthId, {
        bookings: action.payload.data.bookings,
        fetchBookingsInProgress: false,
      })
      Object.assign(state, updatedCalendarMonth);
    },
    fetchBookingsError: (state, action) => {
      const updatedCalendarMonth = updateCalendarMonth(state, action.payload.monthId, {
        fetchBookingsError: action.payload.error,
        fetchBookingsInProgress: false,
      });
      Object.assign(state, updatedCalendarMonth);
    },

    fetchExceptionsRequest: (state, action) => {
      const updatedCalendarMonth = updateCalendarMonth(state, action.payload.monthId, {
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      });
      Object.assign(state, updatedCalendarMonth);
    },
    fetchExceptionsSuccess: (state, action) => {
      const updatedCalendarMonth = updateCalendarMonth(state, action.payload.data.monthId, {
        exceptions: action.payload.data.exceptions,
        fetchExceptionsInProgress: false,
      });
      Object.assign(state, updatedCalendarMonth);
    },
    fetchExceptionsError: (state, action) => {
      const updatedCalendarMonth = updateCalendarMonth(state, action.payload.monthId, {
        fetchExceptionsError: action.payload.error,
        fetchExceptionsInProgress: false,
      });
      Object.assign(state, updatedCalendarMonth);
    },

    createExceptionRequest: (state, action) => {
      const { start, end, seats } = action.payload;
      const draft = ensureAvailabilityException({ attributes: { start, end, seats } });
      const exception = { availabilityException: draft, inProgress: true };
      const availabilityCalendar = addException(exception, state.availabilityCalendar);
      Object.assign(state, { availabilityCalendar });
    },

    createExceptionSuccess: (state, action) => {
      const availabilityCalendar = updateException(action.payload.data.exception, state.availabilityCalendar);
      Object.assign(state, { availabilityCalendar });
    },
    createExceptionError: (state, action) => {
      const { availabilityException, error } = action.payload;
      const failedException = { availabilityException, error };
      const availabilityCalendar = updateException(failedException, state.availabilityCalendar);
      Object.assign(state, { availabilityCalendar });
    },


    deleteExceptionRequest: (state, action) => {
      const { id, seats, currentException } = action.payload;

      // We first create temporary exception with given 'seats' count (the default after deletion).
      // This makes it possible to show the UI element immediately with default color that matches
      // with the availability plan.
      const exception = {
        id,
        inProgress: true,
        availabilityException: {
          ...currentException.availabilityException,
          attributes: { ...currentException.availabilityException.attributes, seats },
        },
      };

      const availabilityCalendar = updateException(exception, state.availabilityCalendar);
      Object.assign(state, { availabilityCalendar });
    },

    deleteExceptionSuccess:  (state, action) => {
      const availabilityCalendar = removeException(action.payload.data.exception, state.availabilityCalendar);
      Object.assign(state, { availabilityCalendar });
    },

    deleteExceptionError: (state, action) => {
      const { availabilityException, error } = action.payload;
      const failedException = { availabilityException, error };
      const availabilityCalendar = updateException(failedException, state.availabilityCalendar);
      Object.assign(state, { availabilityCalendar });
    },


    uploadImageRequest: (state, action) => {
      // action.payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [action.payload.id]: { ...action.payload },
      };
      Object.assign(state, {
        images,
        imageOrder: state.imageOrder.concat([action.payload.id]),
        uploadImageError: null,
      });
    },

    uploadImageSuccess: (state, action: PayloadAction<{data: {id: string; imageId: string}}>) => {
      // action.payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = action.payload.data;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      Object.assign(state, { images });
    },

    uploadImageError: (state, action) => {
      // eslint-disable-next-line no-console
      const { id, error } = action.payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      Object.assign(state, { imageOrder, images, uploadImageError: error });
    },

    updateImageOrder: (state, action) => {
      Object.assign(state, { imageOrder: action.payload.imageOrder });
    },

    removeListingImage: (state, action) => {
      const id = action.payload;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      Object.assign(state, { images, imageOrder, removedImageIds });
    },

    savePayoutDetailsRequest: (state) => {
      Object.assign(state, { payoutDetailsSaveInProgress: true });
    },
    savePayoutDetailsError: (state) => {
      Object.assign(state, { payoutDetailsSaveInProgress: false });
    },
    savePayoutDetailsSuccess: (state) => {
      Object.assign(state, { payoutDetailsSaveInProgress: false, payoutDetailsSaved: true });
    },
  }
});

export const actions = slice.actions;
export default slice.reducer;
