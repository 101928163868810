import config from "../config";
import { formatMoney } from "./currency";
import moment from "moment";

export const priceData = (price, discount, intl) => {
  let multiplier = 1;
  if (
    discount?.isActive
    || discount?.dates?.some(d => moment(d).isSame(moment(new Date()).format('YYYY-MM-DD')))
  ) {
    multiplier = 1 - discount.value / 100;
  }
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, multiplier);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

export const isDiscountApplied = discount => discount?.isActive || (discount?.dates?.length > 0
    && discount?.dates?.includes(moment(new Date()).format('YYYY-MM-DD')));

export const getTotalPriceForAllBookedDays = (datesBooked, unitPrice, discount) => {
  return datesBooked.reduce((accum, date) => {
    if (discount?.isActive || discount?.dates?.includes(date)) {
      return accum + unitPrice * (1 - discount.value / 100);
    } else {
      return accum + unitPrice;
    }
  }, 0)
};
