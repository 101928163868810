import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './FAQ.module.css';

const FAQ = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <h2>
        1) Q: Why should I choose Umoozi Vacation Rentals Inc. for my
        bookings?
      </h2>
      <p>
        A: Umoozi Vacation Rentals Inc. is a secure and easy to use platform
        that allows you to book discounted accommodation for your next
        getaway anywhere in the world. We have no hidden costs and charge
        very low commissions to our Hosts, allowing them to pass on the
        savings to you. By using our platform, you can be confident that you
        are getting the highest quality service at the best price.
      </p>

      <h2>
        2) Q: Do I need to create an account or user profile to make a
        booking?
      </h2>
      <p>
        A: Our platform requires users to create an account with verified user
        credentials to complete a booking. This helps us keep your
        transactions secure, allows safe communication with the host, and
        allows you to keep track of your past, pending, and future bookings. All
        user information is kept private in compliance with our policies.
      </p>

      <h2>
        3) Q: How do you keep my information safe?
      </h2>
      <p>
        A: Umoozi Vacation Rentals Inc. does not share user information with
        third parties who are not part of the property rental process.
        Information can be used or shared for the purposes of marketing,
        payment processing, issuing refunds, or facilitating communication
        between the renter and the host. Information may be shared between
        the different office locations of Umoozi Vacation Rentals Inc.
      </p>

      <h2>
        4) Q: Can I cancel my reservation?
      </h2>
      <p>
        A: Reservations can be cancelled on the website or app when your
        travel plans are disrupted. A small 3% cancellation fee shall be
        assessed, and this covers the cost of secure refund processing.
      </p>

      <h2>
        5) Q: How can I get a refund?
      </h2>
      <p>
        A: A refund can be issued if a booking is cancelled by either the guest
        or host up to 7 days before the date of the stay in accordance with our
        policies. Funds shall be refunded to the credit or debit card that was
        used to make the reservation.
      </p>

      <h2>
        6) Q: How much does Umoozi Vacation Rentals Charge for its
        services?
      </h2>
      <p>
        A: Guests who book accommodation through our platform have no fees
        associated with booking thought our platform. Hosts pay a fixed fee of
        12% of the gross amount of the booking. These fees cover expenses
        such as the costs of secure payment processing, customer service and
        marketing. A cancellation fee of 3% may be assessed to the guest or
        host in accordance with our policies.
      </p>

      <h2>
        7) Q: What steps are you taking to prevent the spread of COVID-
        19?
      </h2>
      <p>
        A: Umoozi Vacation Rentals Inc. requires all users of our platform to
        comply with the local health and safety regulations in their jurisdiction.
        We require all hosts to disinfect all high touch surfaces and allow a
        minimum of 3 hours between guest changes to allow enough time for
        proper and thorough cleaning of guest quarters.
      </p>

      <h2>
        8) Q: How can I find more information about travel restrictions to
        my destination?
      </h2>
      <p>
        A: Information about travel restrictions can be found on the CDC
        website at www.cdc.gov and travel.state.gov. Additional resources
        include your destination countries’ embassy website, and the United
        Nations website www.un.org.
      </p>

      <h2>
        9) Q: How can I contact the host if I have questions about my
        booking?
      </h2>
      <p>
        A:  Our platform allows easy and safe chat between the Guest and Host
        to make your stay comfortable and convenient. You can ask your host
        questions such as “what is the door code” to “how do I use the toaster”
        and get immediate feedback.
      </p>

    </div>
  );
};

FAQ.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

FAQ.propTypes = {
  rootClassName: string,
  className: string,
};

export default FAQ;
