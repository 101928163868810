import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as userSettingsActions } from '../../storage/slices/userSiteSettings';
import { SITE_LANGUAGE } from '../../typings/enums';
import { AppStorage } from 'typings/store/store';

import css from './LanguageSwitcher.module.css';

const SITE_LANGUAGES = [
  {
    title: 'EN',
    value: SITE_LANGUAGE.en,
  },
  {
    title: 'FR',
    value: SITE_LANGUAGE.fr,
  },
  {
    title: 'ES',
    value: SITE_LANGUAGE.es,
  },
];

const LanguageSwitcher: React.FC = () => {
  const dispatch = useDispatch();
  const language = useSelector((state: AppStorage) => state.userSiteSettings.userLanguage);
  const [selectedLang, setSelectedLang] = useState(language);

  const setNewLang = () => {
    if (selectedLang !== language) {
      dispatch(userSettingsActions.changeUserLanguage(selectedLang));
    }
  };

  React.useEffect(() => {
    setNewLang();
  }, [selectedLang])

  return (
    <div className={css.switcherWrapper}>
      <select
        className={css.languageSwitcher}
        value={selectedLang}
        onChange={(e) => setSelectedLang(e.target.value as SITE_LANGUAGE)}
      >
        {SITE_LANGUAGES.map((item) => (
          <option value={item.value}>{item.title}</option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
