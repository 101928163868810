import { createSlice } from '@reduxjs/toolkit';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

const slice = createSlice({
  name: 'inboxPage',
  initialState,
  reducers: {
    fetchOrdersOrSalesRequest: (state) => {
      Object.assign(state, {
        fetchInProgress: true,
        fetchOrdersOrSalesError: null,
      });
    },
    fetchOrdersOrSalesSuccess: (state, action) => {
      const transactions = sortedTransactions(action.payload.data.data);

      Object.assign(state, {
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: action.payload.data.meta,
      });
    },
    fetchOrdersOrSalesError: (state, action) => {
      console.error(action.payload); // eslint-disable-line
      Object.assign(state, {
        fetchInProgress: false,
        fetchOrdersOrSalesError: action.payload
      });
    },
  },
})

export const actions = slice.actions;
export default slice.reducer;
