import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialEmail: null,
  submittedEmail: null,
  recoveryError: null,
  recoveryInProgress: false,
  passwordRequested: false,
};

const slice = createSlice({
  name: 'passwordRecoveryPage',
  initialState,
  reducers: {
    passwordRecoveryRequest: (state) => {
      Object.assign(state, {
        submittedEmail: null,
        recoveryInProgress: true,
        recoveryError: null,
      });
    },
    passwordRecoverySuccess: (state, action) => {
      Object.assign(state, {
        submittedEmail: action.payload.email,
        initialEmail: action.payload.email,
        recoveryInProgress: false,
        passwordRequested: true,
      });
    },
    passwordRecoveryError: (state, action) => {
      Object.assign(state, {
        recoveryInProgress: false,
        recoveryError: action.payload.error,
        initialEmail: action.payload.email,
      });
    },
    retypePasswordRecoveryEmail: (state) => {
      Object.assign(state, {
        initialEmail: state.submittedEmail,
        submittedEmail: null,
        passwordRequested: false,
      });
    },
    clearPasswordRecoveryError: (state) => {
      state.recoveryError = null;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
