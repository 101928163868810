import { actions } from './reducer';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from 'storage/slices/stripeConnectAccount';
import { fetchCurrentUser } from 'storage/slices/user';

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(actions.savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(actions.savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(actions.savePayoutDetailsError()));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(actions.setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.stripeAccount) {
      dispatch(fetchStripeAccount());
    }
    return response;
  });
};
