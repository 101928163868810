import { Dispatch } from 'react';

import { actions as editListingPageActions } from './reducer';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../../util/data';
import { monthIdStringInUTC } from '../../../util/dates';
import { storableError } from '../../../util/errors';
import * as log from '../../../util/log';
import { actions as marketplaceDataActions } from 'storage/slices/marketplaceData';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from 'storage/slices/stripeConnectAccount';
import { fetchCurrentUser } from 'storage/slices/user';
import { AppStorage } from 'typings/store/store';

const { UUID } = sdkTypes;

export function requestShowListing(actionPayload: { id: string; include: string[] }) {
  return async (dispatch: Dispatch<unknown>, getState: () => AppStorage, sdk: SdkObject) => {
    dispatch(editListingPageActions.showListingsRequest(actionPayload));
    try {
      const response = await sdk.ownListings.show(actionPayload);
      // EditListingPage fetches new listing data, which also needs to be added to global data
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));
      // In case of success, we'll clear state.EditListingPage (user will be redirected away)
      dispatch(editListingPageActions.showListingsSuccess(response));
      return response;
    } catch (e) {
      return dispatch(editListingPageActions.showListingsError(storableError(e)));
    }
  };
}

export function requestCreateListingDraft(data: any) {
  return async (dispatch: Dispatch<unknown>, getState: () => AppStorage, sdk: SdkObject) => {
    dispatch(editListingPageActions.createListingDraftRequest());

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    try {
      const response = await sdk.ownListings.createDraft(data, queryParams);
      //const id = response.data.data.id.uuid;
      // Add the created listing to the marketplace data
      dispatch(marketplaceDataActions.addMarketplaceEntities(response));

      // Modify store to understand that we have created listing and can redirect away
      dispatch(editListingPageActions.createListingDraftSuccess(response));
      return response;
    } catch (e) {
      log.error(e as any, 'create-listing-draft-failed', { listingData: data });
      return dispatch(editListingPageActions.createListingDraftError(storableError(e)));
    }
  };
}

export const requestPublishListingDraft = (listingId: any) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStorage,
  sdk: SdkObject
) => {
  dispatch(editListingPageActions.publishListingRequest(listingId));

  try {
    const response = await sdk.ownListings.publishDraft({ id: listingId }, { expand: true });
    // Add the created listing to the marketplace data
    dispatch(marketplaceDataActions.addMarketplaceEntities(response));
    dispatch(editListingPageActions.publishListingSuccess());
    return response;
  } catch (e) {
    dispatch(editListingPageActions.publishListingError(storableError(e)));
  }
};

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload: { id: any; file: any }) {
  return (dispatch: Dispatch<unknown>, getState: () => AppStorage, sdk: SdkObject) => {
    const id = actionPayload.id;
    dispatch(editListingPageActions.uploadImageRequest(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file })
      .then((resp: { data: { data: { id: string } } }) =>
        dispatch(
          editListingPageActions.uploadImageSuccess({ data: { id, imageId: resp.data.data.id } })
        )
      )
      .catch((e: Error) =>
        dispatch(editListingPageActions.uploadImageError({ id, error: storableError(e) }))
      );
  };
}

export const requestFetchBookings = (fetchParams: {
  listingId: any;
  start: any;
  end: any;
  state: any;
}) => async (dispatch: Dispatch<unknown>, getState: () => AppStorage, sdk: SdkObject) => {
  const { listingId, start, end, state } = fetchParams;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  dispatch(editListingPageActions.fetchBookingsRequest({ ...fetchParams, monthId }));

  try {
    const response = await sdk.bookings.query({ listingId, start, end, state }, { expand: true });
    const bookings = denormalisedResponseEntities(response);
    return dispatch(editListingPageActions.fetchBookingsSuccess({ data: { monthId, bookings } }));
  } catch (e) {
    return dispatch(
      editListingPageActions.fetchBookingsError({ monthId, error: storableError(e) })
    );
  }
};

export const requestFetchAvailabilityExceptions = (fetchParams: {
  listingId: any;
  start: any;
  end: any;
}) => async (dispatch: Dispatch<unknown>, getState: () => AppStorage, sdk: SdkObject) => {
  const { listingId, start, end } = fetchParams;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  dispatch(editListingPageActions.fetchExceptionsRequest({ ...fetchParams, monthId }));

  try {
    const response = await sdk.availabilityExceptions.query(
      { listingId, start, end },
      { expand: true }
    );
    const exceptions = denormalisedResponseEntities(response).map(availabilityException => ({
      availabilityException,
    }));
    return dispatch(
      editListingPageActions.fetchExceptionsSuccess({ data: { monthId, exceptions } })
    );
  } catch (e) {
    return dispatch(
      editListingPageActions.fetchExceptionsError({ monthId, error: storableError(e) })
    );
  }
};

export const requestCreateAvailabilityException = (params: any) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStorage,
  sdk: SdkObject
) => {
  const { currentException, ...createParams } = params;

  dispatch(editListingPageActions.createExceptionRequest(createParams));

  try {
    const response = await sdk.availabilityExceptions.create(createParams, { expand: true });
    dispatch(
      editListingPageActions.createExceptionSuccess({
        data: {
          exception: {
            availabilityException: response.data.data,
          },
        },
      })
    );
    return response;
  } catch (error) {
    const availabilityException = currentException && currentException.availabilityException;
    return dispatch(
      editListingPageActions.createExceptionError({
        error: storableError(error),
        availabilityException,
      })
    );
  }
};

export const requestDeleteAvailabilityException = (params: any) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStorage,
  sdk: SdkObject
) => {
  const { currentException, seats, ...deleteParams } = params;

  dispatch(editListingPageActions.deleteExceptionRequest(params));

  try {
    const response = await sdk.availabilityExceptions.delete(deleteParams, { expand: true });
    dispatch(
      editListingPageActions.deleteExceptionSuccess({
        data: {
          exception: currentException,
        },
      })
    );
    return response;
  } catch (error) {
    const availabilityException = currentException && currentException.availabilityException;
    return dispatch(
      editListingPageActions.deleteExceptionError({
        error: storableError(error),
        availabilityException,
      })
    );
  }
};

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab: any, data: any) {
  return (dispatch: Dispatch<unknown>, getState: any, sdk: SdkObject) => {
    dispatch(editListingPageActions.updateListingRequest());
    const { id } = data;

    let updateResponse: void;
    return sdk.ownListings
      .update(data)
      .then(response => {
        updateResponse = response;
        const payload = {
          id,
          include: ['author', 'images'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        };
        return dispatch(requestShowListing(payload));
      })
      .then(() => {
        dispatch(editListingPageActions.markTabUpdated(tab));
        dispatch(editListingPageActions.updateListingSuccess());
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(editListingPageActions.updateListingError(storableError(e)));
      });
  };
}

export const savePayoutDetails = (values: any, isUpdateCall: any) => (
  dispatch: any,
  getState: () => AppStorage,
  sdk: SdkObject
) => {
  const upsertThunk: any = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(editListingPageActions.savePayoutDetailsRequest());

  return dispatch(upsertThunk(values))
    .then((response: any) => {
      dispatch(editListingPageActions.savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(editListingPageActions.savePayoutDetailsError()));
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = (params: { id: any; type: any }) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStorage,
  sdk: SdkObject
) => {
  dispatch(editListingPageActions.clearUpdatedTab());
  const { id, type } = params;

  if (type === 'new') {
    // No need to listing data when creating a new listing
    try {
      const response = await Promise.all([dispatch(fetchCurrentUser())]);
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  const payload = {
    id: new UUID(id),
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  try {
    const response_1 = await Promise.all([
      dispatch(requestShowListing(payload)),
      dispatch(fetchCurrentUser()),
    ]);
    const currentUser_1 = getState().user.currentUser;
    if (currentUser_1 && currentUser_1.stripeAccount) {
      dispatch(fetchStripeAccount());
    }
    return response_1;
  } catch (e_1) {
    throw e_1;
  }
};
