import { createSlice } from '@reduxjs/toolkit';
import { uuid as uuidv4 } from 'uuidv4';

import { denormalisedResponseEntities, ensureOwnListing } from '../../util/data';
import { storableError } from '../../util/errors';
import { transitionsToRequested } from '../../util/transaction';
import { LISTING_STATE_DRAFT } from '../../util/types';
import * as log from '../../util/log';
import { util as sdkUtil } from '../../util/sdkLoader';
import { MAX_FAVORITE_LISTS } from '../../util/constants';
import { authInfo } from './auth';
import { actions as stripeConnectAccountActions } from 'storage/slices/stripeConnectAccount';
import { actions as manageBookingsPageActions } from 'storage/slices/manageBookingsPage';
import { actions as searchPageActions } from 'storage/slices/searchPage';

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
      ? newCurrentUser
      : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    currentUserShowRequest: (state) => {
      state.currentUserShowError = null
    },
    currentUserShowSuccess: (state, action) => {
      state.currentUser = mergeCurrentUser(state.currentUser, action.payload);
    },
    currentUserShowError: (state, action) => {
      state.currentUserShowError = action.payload;
    },
    clearCurrentUser: (state) => {
      Object.assign(state, {
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      });
    },
    fetchCurrentUserHasListingsRequest: (state) => {
      state.currentUserHasListingsError = null;
    },
    fetchCurrentUserHasListingsSuccess: (state, action) => {
      state.currentUserHasListings = action.payload;
    },
    fetchCurrentUserHasListingsError: (state, action) => {
      state.currentUserHasListingsError = action.payload
    },
    fetchCurrentUserNotificationsRequest: (state) => {
      state.currentUserNotificationCountError = null;
    },
    fetchCurrentUserNotificationsSuccess: (state, action) => {
      state.currentUserNotificationCount = action.payload.length;
    },
    fetchCurrentUserNotificationsError: (state, action) => {
      state.currentUserNotificationCountError = action.payload;
    },
    fetchCurrentUserHasOrdersRequest: (state) => {
      state.currentUserHasOrdersError = null;
    },
    fetchCurrentUserHasOrdersSuccess: (state, action) => {
      state.currentUserHasOrders = action.payload;
    },
    fetchCurrentUserHasOrdersError: (state, action) => {
      state.currentUserHasOrdersError = action.payload;
    },
    sendVerificationEmailRequest: (state) => {
      Object.assign(state, {
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      });
    },
    sendVerificationEmailSuccess: (state) => {
      state.sendVerificationEmailInProgress = false;
    },
    sendVerificationEmailError: (state, action) => {
      Object.assign(state, {
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: action.payload,
      });
    },
    updateFavoriteBookings: (state, action) => {
      state.currentUser.attributes.profile.publicData.favorites = action.payload;
    },
  }
})

export const actions = slice.actions;

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(actions.fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(actions.fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(actions.fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(actions.fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(actions.fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(actions.fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(actions.fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(actions.fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(actions.fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(actions.fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(actions.fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(actions.currentUserShowRequest());
  const { isAuthenticated } = getState().auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(actions.currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeConnectAccountActions.stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(actions.currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(actions.currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(actions.sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(actions.sendVerificationEmailSuccess()))
    .catch(e => dispatch(actions.sendVerificationEmailError(storableError(e))));
};

export const {
  CREATE_LIST,
  DELETE_LIST,
  RENAME_LIST,
  UPDATE_LISTINGS,
  DELETE_LISTING,
} = {
  CREATE_LIST: 'create-list',
  DELETE_LIST: 'delete-list',
  RENAME_LIST: 'rename-list',
  UPDATE_LISTINGS: 'update-listings',
  DELETE_LISTING: 'delete-listing',
};

const getUpdatedFavorites = (actionType, data, favorites) => {
  const {
    listId = '',
    listName = '',
    listsIds = [],
    listingId = '',
  } = data;

  switch (actionType) {
    case CREATE_LIST: {
      const newFavoritesList = {
        'id': uuidv4(),
        'listings': [],
        'name': listName
      }
      return [...favorites, newFavoritesList];
    }
    case DELETE_LIST: {
      return favorites.filter(list => list.id !== listId);
    }
    case RENAME_LIST: {
      return favorites.map(list => {
        if (list.id !== listId) return list;
        return {
          ...list,
          name: listName,
        }
      });
    }
    case UPDATE_LISTINGS: {
      return favorites.map(list => {
        if (listsIds.includes(list.id)) {
          if (!list.listings.includes(listingId)) {
            return {
              ...list,
              listings: [...list.listings, listingId],
            }
          }
        } else {
          return {
            ...list,
            listings: list.listings.filter(id => id !== listingId),
          }
        }
        return list;
      });
    }
    case DELETE_LISTING: {
      return favorites.map(list => {
        if (list.id !== listId) return list;
        return {
          ...list,
          listings: list.listings.filter(id => id !== listingId)
        }
      });
    }
  }
}

export const updateFavorites = (actionType, data) => {
  return (dispatch, getState, sdk) => {
    const queryParams = {
      expand: true,
    };

    return sdk.currentUser.show({
      include: ['favorites'],
    }).then((response) => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }

      const favorites = entities[0].attributes.profile.publicData.favorites || [];
      if (actionType === CREATE_LIST && favorites.length >= MAX_FAVORITE_LISTS) {
        return;
      }
      const updatedFavorites = getUpdatedFavorites(actionType, data, favorites);

      return sdk.currentUser
        .updateProfile({ publicData: { favorites: updatedFavorites }}, queryParams)
        .then(() => {
          dispatch(actions.updateFavoriteBookings(updatedFavorites));
          dispatch(searchPageActions.setFavoriteLists(updatedFavorites));
          if (actionType === CREATE_LIST) {
            dispatch(manageBookingsPageActions.loadDataForFavorites());
          }
        })
        .catch(e => {
          console.log('error on sdk.currentUser.updateProfile: ', e)
        })
    })
  }
}


export default slice.reducer;
