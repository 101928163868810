import { createSlice } from '@reduxjs/toolkit';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';

// ================ Reducer ================ //

const initialState = {
  confirmCardPaymentInProgress: false,
  confirmCardPaymentError: null,
  handleCardSetupInProgress: false,
  handleCardSetupError: null,
  paymentIntent: null,
  setupIntent: null,
  retrievePaymentIntentInProgress: false,
  retrievePaymentIntentError: null,
};

export const slice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    stripeAccountClearError: (state) => {
      Object.assign(state, initialState);
    },
    accountOpenerCreateSuccess: (state, action) => {
      Object.assign(state, {
        createAccountOpenerInProgress: false,
        personAccountOpener: action.payload
      });
    },
    accountOpenerCreateRequest: (state, action) => {
      Object.assign(state, {
        createAccountOpenerError: null,
        createAccountOpenerInProgress: true,
      });
    },
    accountOpenerCreateError: (state, action) => {
      Object.assign(state, {
        createAccountOpenerError: action.payload,
        createAccountOpenerInProgress: false
      });
    },
    //
    personCreateRequest: (state, action) => {
      Object.assign(state, {
        persons: [
          ...state.persons,
          {
            ...action.payload,
            createStripePersonError: null,
            createStripePersonInProgress: true,
          },
        ],
      });
    },
    personCreateSuccess: (state, action) => {
      Object.assign(state, {
        persons: state.persons.map(p => {
          return p.personToken === action.payload.personToken
            ? { ...action.payload, createStripePersonInProgress: false }
            : p;
        }),
      });
    },
    personCreateError: (state, action) => {
      Object.assign(state, {
        persons: state.persons.map(p => {
          return p.personToken === action.payload.personToken
            ? { ...p, createStripePersonInProgress: false, createStripePersonError: action.payload.error }
            : p;
        }),
      });
    },
    confirmCardPaymentRequest: (state) => {
      Object.assign(state, {
        confirmCardPaymentError: null,
        confirmCardPaymentInProgress: true,
      });
    },
    confirmCardPaymentSuccess: (state, action) => {
      Object.assign(state, {
        paymentIntent: action.payload,
        confirmCardPaymentInProgress: false
      });
    },
    confirmCardPaymentError: (state, action) => {
      Object.assign(state, {
        confirmCardPaymentError: action.payload,
        confirmCardPaymentInProgress: false
      });
    },
    handleCardSetupRequest: (state) => {
      Object.assign(state, {
        handleCardSetupError: null,
        handleCardSetupInProgress: true,
      });
    },
    handleCardSetupSuccess: (state, action) => {
      Object.assign(state, {
        setupIntent: action.payload,
        handleCardSetupInProgress: false
      });
    },
    handleCardSetupError: (state, action) => {
      Object.assign(state, {
        handleCardSetupError: action.payload,
        handleCardSetupInProgress: false
      });
    },
    initializeCardPaymentData: (state) => {
      Object.assign(state, {
        confirmCardPaymentInProgress: false,
        confirmCardPaymentError: null,
        paymentIntent: null,
      });
    },
    retrievePaymentIntentRequest: (state) => {
      Object.assign(state, {
        retrievePaymentIntentError: null,
        retrievePaymentIntentInProgress: true,
      });
    },
    retrievePaymentIntentSuccess: (state, action) => {
      Object.assign(state, {
        paymentIntent: action.payload,
        retrievePaymentIntentInProgress: false
      });
    },
    retrievePaymentIntentError: (state, action) => {
      Object.assign(state, {
        retrievePaymentIntentError: action.payload,
        retrievePaymentIntentInProgress: false,
      });
    },
  },
});

// ================ Thunks ================ //

export const retrievePaymentIntent = params => dispatch => {
  const { stripe, stripePaymentIntentClientSecret } = params;
  dispatch(actions.retrievePaymentIntentRequest());

  return stripe
    .retrievePaymentIntent(stripePaymentIntentClientSecret)
    .then(response => {
      if (response.error) {
        return Promise.reject(response);
      } else {
        dispatch(actions.retrievePaymentIntentSuccess(response.paymentIntent));
        return response;
      }
    })
    .catch(err => {
      // Unwrap Stripe error.
      const e = err.error || storableError(err);
      dispatch(actions.retrievePaymentIntentError(e));

      // Log error
      const { code, doc_url, message, payment_intent } = err.error || {};
      const loggableError = err.error
        ? {
          code,
          message,
          doc_url,
          paymentIntentStatus: payment_intent
            ? payment_intent.status
            : 'no payment_intent included',
        }
        : e;
      log.error(loggableError, 'stripe-retrieve-payment-intent-failed', {
        stripeMessage: loggableError.message,
      });
      throw err;
    });
};

export const confirmCardPayment = params => dispatch => {
  // It's required to use the same instance of Stripe as where the card has been created
  // so that's why Stripe needs to be passed here and we can't create a new instance.
  const { stripe, paymentParams, stripePaymentIntentClientSecret } = params;
  const transactionId = params.orderId;

  dispatch(actions.confirmCardPaymentRequest());

  // When using default payment method paymentParams.payment_method is
  // already set Flex API side, when request-payment transition is made
  // so there's no need for paymentParams
  const args = paymentParams
    ? [stripePaymentIntentClientSecret, paymentParams]
    : [stripePaymentIntentClientSecret];

  return stripe
    .confirmCardPayment(...args)
    .then(response => {
      if (response.error) {
        return Promise.reject(response);
      } else {
        dispatch(actions.confirmCardPaymentSuccess(response));
        return { ...response, transactionId };
      }
    })
    .catch(err => {
      // Unwrap Stripe error.
      const e = err.error || storableError(err);
      dispatch(actions.confirmCardPaymentError(e));

      // Log error
      const containsPaymentIntent = err.error && err.error.payment_intent;
      const { code, doc_url, message, payment_intent } = containsPaymentIntent ? err.error : {};
      const loggableError = containsPaymentIntent
        ? {
          code,
          message,
          doc_url,
          paymentIntentStatus: payment_intent.status,
        }
        : e;
      log.error(loggableError, 'stripe-handle-card-payment-failed', {
        stripeMessage: loggableError.message,
      });
      throw e;
    });
};

export const handleCardSetup = params => dispatch => {
  // It's required to use the same instance of Stripe as where the card has been created
  // so that's why Stripe needs to be passed here and we can't create a new instance.
  const { stripe, card, setupIntentClientSecret, paymentParams } = params;

  dispatch(actions.handleCardSetupRequest());

  return stripe
    .handleCardSetup(setupIntentClientSecret, card, paymentParams)
    .then(response => {
      if (response.error) {
        return Promise.reject(response);
      } else {
        dispatch(actions.handleCardSetupSuccess(response));
        return response;
      }
    })
    .catch(err => {
      // Unwrap Stripe error.
      const e = err.error || storableError(err);
      dispatch(actions.handleCardSetupError(e));

      // Log error
      const containsSetupIntent = err.error && err.error.setup_intent;
      const { code, doc_url, message, setup_intent } = containsSetupIntent ? err.error : {};
      const loggableError = containsSetupIntent
        ? {
          code,
          message,
          doc_url,
          paymentIntentStatus: setup_intent.status,
        }
        : e;
      log.error(loggableError, 'stripe-handle-card-setup-failed', {
        stripeMessage: loggableError.message,
      });
      throw e;
    });
};


export const actions = slice.actions;
export default slice.reducer;
