import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaMail.module.css';

const IconSocialMediaMail = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M23 11H9C8.73478 11 8.48043 11.0937 8.29289 11.2603C8.10536 11.427 8 11.6531 8 11.8889V20.7778C8 21.0135 8.10536 21.2396 8.29289 21.4063C8.48043 21.573 8.73478 21.6667 9 21.6667H23C23.2652 21.6667 23.5196 21.573 23.7071 21.4063C23.8946 21.2396 24 21.0135 24 20.7778V11.8889C24 11.6531 23.8946 11.427 23.7071 11.2603C23.5196 11.0937 23.2652 11 23 11ZM22.23 20.7778H9.83L13.33 17.56L12.61 16.9422L9 20.2622V12.5644L15.215 18.0622C15.4024 18.2278 15.6558 18.3207 15.92 18.3207C16.1842 18.3207 16.4376 18.2278 16.625 18.0622L23 12.4267V20.2044L19.32 16.9333L18.615 17.56L22.23 20.7778ZM9.655 11.8889H22.19L15.92 17.4311L9.655 11.8889Z" fill="#4A4A4A"/>
    </svg>
  );
};

IconSocialMediaMail.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaMail.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaMail;
