import { Dispatch } from 'react';

import { AppStorage } from '../../../typings/store/store';
import { sendCvByEmail } from '../../../util/api';
import { actions as careerPageActions} from 'storage/slices/carreersPage';

export const sendCvEmail = (body: any) => (dispatch: Dispatch<unknown>, getState: () => AppStorage, sdk: SdkObject) => {
  dispatch(careerPageActions.setResumeSendPending(true));

  sendCvByEmail(body)
    .then(res => {
      if (res.ok) {
        dispatch(careerPageActions.setEmailSuccess(true));
        dispatch(careerPageActions.setEmailError(false));
      } else {
        dispatch(careerPageActions.setEmailError(true));
        dispatch(careerPageActions.setEmailSuccess(false));
      }
    })
    .finally(() => {
      dispatch(careerPageActions.setResumeSendPending(false));
    })
}
