import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
};

const slice = createSlice({
  name: 'checkoutPage',
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      Object.assign(state, {
        ...initialState, ...action.payload
      });
    },
    speculateTransactionRequest: (state) => {
      Object.assign(state, {
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      });
    },
    speculateTransactionSuccess: (state, action) => {
      Object.assign(state, {
        speculateTransactionInProgress: false,
        speculatedTransaction: action.payload,
      });
    },
    speculateTransactionError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
      Object.assign(state, {
        speculateTransactionInProgress: false,
        speculateTransactionError: action.payload,
      });
    },
    initiateOrderRequest: (state) => {
      state.initiateOrderError = null;
    },
    initiateOrderSuccess: (state, action) => {
      state.transaction = action.payload;
    },
    initiateOrderError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
      state.initiateOrderError = action.payload;
    },
    confirmPaymentRequest: (state, action) => {
      state.confirmPaymentError = null;
    },
    confirmPaymentSuccess: (state, action) => {
      return state;
    },
    confirmPaymentError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
      state.confirmPaymentError = action.payload;
    },
    stripeCustomerRequest: (state) => {
      state.stripeCustomerFetched = false;
    },
    stripeCustomerSuccess: (state) => {
      state.stripeCustomerFetched = true;
    },
    stripeCustomerError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
      state.stripeCustomerFetchError = action.payload;
    },
  }
});

export const actions = slice.actions;
export default slice.reducer;
