import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';

import css from './DeleteFavoritesListModal.module.css';

export interface DeleteFavoritesListModalProps {
  isOpen?: boolean;
  manageDisableScrolling: any;
  onClose: () => void;
  onDelete: () => void;
  listName: string;
}

export const DeleteFavoritesListModal = ({
  isOpen = false,
  manageDisableScrolling,
  onClose,
  onDelete,
  listName,
}: DeleteFavoritesListModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={manageDisableScrolling}
      id='deleteFavoritesList'
      containerClassName={css.container}
    >
      <div className={css.header}>
        <h2 className={css.title}>
          <FormattedMessage id="DeleteFavoritesListModal.title" />
        </h2>
      </div>
      <div className={css.content}>
        <p className={css.hint}>
          <FormattedMessage
            id="DeleteFavoritesListModal.hint"
            values={{ listName: listName }}
          />
        </p>
        <div className={css.buttonsContainer}>
          <Button className={css.cancelButton} onClick={onClose}>
            <FormattedMessage id="DeleteFavoritesListModal.cancel" />
          </Button>
          <Button className={css.deleteButton} onClick={onDelete}>
            <FormattedMessage id="DeleteFavoritesListModal.delete" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFavoritesListModal;
