import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-miami',
    predictionPlace: {
      address: 'Miami, Florida, United States',
      bounds: new LatLngBounds(new LatLng(25.979435196, -80.130891014), new LatLng(25.53424901, -80.873602)),
    },
  },
  {
    id: 'default-cancun',
    predictionPlace: {
      address: 'Cancún, Quintana Roo, Mexico',
      bounds: new LatLngBounds(new LatLng(21.2130333805118, -86.7405402017646), new LatLng(21.0297633301856, -86.9712121848562)),
    },
  },
  {
    id: 'default-orlando',
    predictionPlace: {
      address: 'Orlando, Florida, United States',
      bounds: new LatLngBounds(new LatLng(28.791397996, -80.569423003), new LatLng(28.340635005, -81.615296162)),
    },
  },
];
export default defaultLocations;
