import { createSlice } from '@reduxjs/toolkit';
// ================ Action types ================ //

export const LOCATION_CHANGED = 'app/Routing/LOCATION_CHANGED';

// ================ Reducer ================ //

const initialState = {
  currentLocation: null,
  currentCanonicalUrl: null,
};

export const slice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    locationChanged: (state, action) => {
      return Object.assign(state, {
        currentLocation: action.payload.location,
        currentCanonicalUrl: action.payload.canonicalUrl,
      })

    },
  },
});

export const actions = slice.actions;
export default slice.reducer;

// ================ Action creators ================ //

export const locationChanged = (location, canonicalUrl) => ({
  type: LOCATION_CHANGED,
  payload: { location, canonicalUrl },
});
