// This file deals with Flex API which will create Stripe Custom Connect accounts
// from given bank_account tokens.
import config from '../../config';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { createSlice } from '@reduxjs/toolkit';

// ================ Reducer ================ //

const initialState = {
  createStripeAccountInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountInProgress: false,
  updateStripeAccountError: null,
  fetchStripeAccountInProgress: false,
  fetchStripeAccountError: null,
  getAccountLinkInProgress: false,
  getAccountLinkError: null,
  stripeAccount: null,
  stripeAccountFetched: false,
};

export const slice = createSlice({
  name: 'stripeConnectAccount',
  initialState,
  reducers: {
    stripeAccountCreateRequest: state => {
      Object.assign(state, {
        createStripeAccountError: null,
        createStripeAccountInProgress: true,
      });
    },
    stripeAccountCreateSuccess: (state, action) => {
      Object.assign(state, {
        createStripeAccountInProgress: false,
        stripeAccount: action.payload,
        stripeAccountFetched: true,
      });
    },
    stripeAccountCreateError: (state, action) => {
      Object.assign(state, {
        createStripeAccountError: action.payload,
        createStripeAccountInProgress: false,
      });
    },
    //
    stripeAccountUpdateRequest: state => {
      Object.assign(state, {
        updateStripeAccountError: null,
        updateStripeAccountInProgress: true,
      });
    },
    stripeAccountUpdateSuccess: (state, action) => {
      Object.assign(state, {
        updateStripeAccountInProgress: false,
        stripeAccount: action.payload,
        stripeAccountFetched: true,
      });
    },
    stripeAccountUpdateError: (state, action) => {
      Object.assign(state, {
        updateStripeAccountError: action.payload,
        createStripeAccountInProgress: false,
      });
    },
    //
    stripeAccountFetchRequest: state => {
      Object.assign(state, {
        fetchStripeAccountError: null,
        fetchStripeAccountInProgress: true,
      });
    },
    stripeAccountFetchSuccess: (state, action) => {
      Object.assign(state, {
        fetchStripeAccountInProgress: false,
        stripeAccount: action.payload,
        stripeAccountFetched: true,
      });
    },
    stripeAccountFetchError: (state, action) => {
      Object.assign(state, {
        updateStripeAccountError: action.payload,
        createStripeAccountInProgress: false,
      });
    },
    stripeAccountClearError: (state) => {
      Object.assign(state, initialState);
    },
    getAccountLinkRequest: state => {
      return Object.assign(state, {
        getAccountLinkError: null,
        getAccountLinkInProgress: true,
      });
    },
    getAccountLinkError: (state, action) => {
      return Object.assign(state, {
        getAccountLinkInProgress: false,
        getAccountLinkError: action.payload,
      });
    },
    getAccountLinkSuccess: state => {
      return Object.assign(state, {
        getAccountLinkInProgress: false,
      });
    },
  },
});

export const actions = slice.actions;

// ================ Thunks ================ //

export const createStripeAccount = params => (dispatch, getState, sdk) => {
  if (typeof window === 'undefined' || !window.Stripe) {
    throw new Error('Stripe must be loaded for submitting PayoutPreferences');
  }
  const stripe = window.Stripe(config.stripe.publishableKey);

  const { country, accountType, bankAccountToken, businessProfileMCC, businessProfileURL } = params;

  // Capabilities are a collection of settings that can be requested for each provider.
  // What Capabilities are required determines what information Stripe requires to be
  // collected from the providers.
  // You can read more from here: https://stripe.com/docs/connect/capabilities-overview
  // In Flex both 'card_payments' and 'transfers' are required.
  const requestedCapabilities = ['card_payments', 'transfers'];

  const accountInfo = {
    business_type: accountType,
    tos_shown_and_accepted: true,
  };

  dispatch(actions.stripeAccountCreateRequest());

  return stripe
    .createToken('account', accountInfo)
    .then(response => {
      const accountToken = response.token.id;
      return sdk.stripeAccount.create(
        {
          country,
          accountToken,
          bankAccountToken,
          requestedCapabilities,
          businessProfileMCC,
          businessProfileURL,
        },
        { expand: true }
      );
    })
    .then(response => {
      const stripeAccount = response.data.data;
      dispatch(actions.stripeAccountCreateSuccess(stripeAccount));
      return stripeAccount;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(actions.stripeAccountCreateError(e));
      const stripeMessage =
        e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
          ? e.apiErrors[0].meta.stripeMessage
          : null;
      log.error(err, 'create-stripe-account-failed', { stripeMessage });
      throw e;
    });
};

// This function is used for updating the bank account token
// but could be expanded to other information as well.
//
// If the Stripe account has been created with account token,
// you need to use account token also to update the account.
// By default the account token will not be used.
// See API reference for more information:
// https://www.sharetribe.com/api-reference/?javascript#update-stripe-account
export const updateStripeAccount = params => (dispatch, getState, sdk) => {
  const bankAccountToken = params.bankAccountToken;

  dispatch(actions.stripeAccountUpdateRequest());
  return sdk.stripeAccount
    .update(
      { bankAccountToken, requestedCapabilities: ['card_payments', 'transfers'] },
      { expand: true }
    )
    .then(response => {
      const stripeAccount = response.data.data;
      dispatch(actions.stripeAccountUpdateSuccess(stripeAccount));
      return stripeAccount;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(actions.stripeAccountUpdateError(e));
      const stripeMessage =
        e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
          ? e.apiErrors[0].meta.stripeMessage
          : null;
      log.error(err, 'update-stripe-account-failed', { stripeMessage });
      throw e;
    });
};

export const fetchStripeAccount = params => (dispatch, getState, sdk) => {
  dispatch(actions.stripeAccountFetchRequest());

  return sdk.stripeAccount
    .fetch()
    .then(response => {
      const stripeAccount = response.data.data;
      dispatch(actions.stripeAccountFetchSuccess(stripeAccount));
      return stripeAccount;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(actions.stripeAccountFetchError(e));
      const stripeMessage =
        e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
          ? e.apiErrors[0].meta.stripeMessage
          : null;
      log.error(err, 'fetch-stripe-account-failed', { stripeMessage });
      throw e;
    });
};

export const getStripeConnectAccountLink = params => (dispatch, getState, sdk) => {
  const { failureURL, successURL, type } = params;
  dispatch(actions.getAccountLinkRequest());

  return sdk.stripeAccountLinks
    .create({
      failureURL,
      successURL,
      type,
      collect: 'currently_due',
    })
    .then(response => {
      // Return the account link
      return response.data.data.attributes.url;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(actions.getAccountLinkError(e));
      const stripeMessage =
        e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
          ? e.apiErrors[0].meta.stripeMessage
          : null;
      log.error(err, 'get-stripe-account-link-failed', { stripeMessage });
      throw e;
    });
};

export default slice.reducer;
