import React, { useRef } from 'react';
import { Field } from 'react-final-form';

import { LocationAutocompleteInput } from '../index';
import { FormattedMessage } from '../../util/reactIntl';
import Modal from '../../components/Modal/Modal';
import { manageDisableScrolling } from '../../storage/slices/ui';
import { CURRENT_LOCATION_ID } from 'components/LocationAutocompleteInput/GeocoderMapbox';

import css from './DestinationModal.module.css';

const identity = v => v;

const DestinationModal = props => {
  const {
    openLocationModal,
    setOpenLocationModal,
    setSearchLocation,
    intl,
    setOpenDateRangeModal,
  }= props;
  const modalRef = useRef();

  return (
    <div ref={modalRef} className={css.container}>
      <Modal
        isOpen={openLocationModal}
        onClose={() => {
          setOpenLocationModal(false);
        }}
        onManageDisableScrolling={manageDisableScrolling}
        id='dateRange'
      >
        <div className={css.modalTitle}>
          <FormattedMessage id='TopbarNewSearchForm.destinationModalTitle' />
        </div>
        <label htmlFor='city' className={css.label}>
          <FormattedMessage id='TopbarNewSearchForm.destinationModalLabel' />
        </label>
          <Field
            name='location'
            format={identity}
            render={({ input, meta }) => {
              const { onChange, ...restInput } = input;
              const searchOnChange = value => {
                const isCurrentLocation = value.selectedPlace?.address === CURRENT_LOCATION_ID;
                const formattedValue = isCurrentLocation ? {
                  ...value,
                  search: intl.formatMessage({ id: "LocationAutocompleteInput.currentLocation" }),
                } : value;

                onChange(formattedValue);
                setSearchLocation(formattedValue);
              };

              const searchInput = { ...restInput, onChange: searchOnChange };

              return (
                <LocationAutocompleteInput
                  iconClassName={css.icon}
                  inputClassName={css.input}
                  placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                  closeOnBlur
                  input={searchInput}
                  meta={meta}
                  autoFocus
                />
              );
            }}
          />
        <div className={css.buttonContainer}>
          <button
            type='button'
            className={css.nextButton}
            onClick={() => {
              setOpenLocationModal(false);
              setOpenDateRangeModal(true);
            }}
          >
            <FormattedMessage id='TopbarNewSearchForm.nextButton' />
          </button>
        </div>
      </Modal>
    </div>
  )
};

export default DestinationModal;
