import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaPhone.module.css';

const IconSocialMediaPhone = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M21.9429 23.625H21.8472C10.7941 22.9894 9.22473 13.6631 9.00536 10.8169C8.98768 10.5956 9.01385 10.373 9.08235 10.1618C9.15085 9.95062 9.26035 9.75505 9.40457 9.58627C9.54879 9.41749 9.7249 9.27882 9.9228 9.17822C10.1207 9.07762 10.3365 9.01706 10.5579 9H13.6572C13.8825 8.99978 14.1027 9.06722 14.2893 9.19359C14.4758 9.31996 14.6201 9.49943 14.7035 9.70875L15.5585 11.8125C15.6408 12.017 15.6612 12.2412 15.6172 12.4572C15.5732 12.6732 15.4667 12.8715 15.311 13.0275L14.1129 14.2369C14.3 15.3004 14.8093 16.2808 15.5719 17.0454C16.3345 17.81 17.3136 18.3219 18.3766 18.5119L19.5972 17.3025C19.7556 17.1485 19.9558 17.0445 20.1728 17.0034C20.3899 16.9624 20.6142 16.9862 20.8179 17.0719L22.9385 17.9213C23.1447 18.0072 23.3205 18.1527 23.4438 18.339C23.567 18.5253 23.6319 18.7441 23.6304 18.9675V21.9375C23.6304 22.3851 23.4526 22.8143 23.1361 23.1307C22.8196 23.4472 22.3904 23.625 21.9429 23.625ZM10.6929 10.125C10.5437 10.125 10.4006 10.1843 10.2951 10.2898C10.1896 10.3952 10.1304 10.5383 10.1304 10.6875V10.7325C10.3891 14.0625 12.0485 21.9375 21.9091 22.5C21.983 22.5046 22.0571 22.4945 22.1271 22.4704C22.1971 22.4463 22.2616 22.4086 22.317 22.3594C22.3724 22.3103 22.4176 22.2507 22.4499 22.1841C22.4822 22.1175 22.5011 22.0452 22.5054 21.9712V18.9675L20.3847 18.1181L18.7704 19.7212L18.5004 19.6875C13.6066 19.0744 12.9429 14.1806 12.9429 14.13L12.9091 13.86L14.5066 12.2456L13.6629 10.125H10.6929Z" fill="#4A4A4A"/>
    </svg>
  );
};

IconSocialMediaPhone.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaPhone.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaPhone;
