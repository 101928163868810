import React, { useEffect, useRef, useState } from 'react';
import { Button, FieldTextInput, Form, Modal } from 'components';
import { Form as FinalForm, FormProps } from 'react-final-form';

import { FormattedMessage } from '../../util/reactIntl';
import {
  FAVORITE_LIST_NAME_MAX_LENGTH,
  required,
  maxLength,
  composeValidators,
} from '../../util/validators';

import css from './FavoriteListSettingsForm.module.css';


interface FavoriteListSettingsFormProps extends FormProps {
  rootClassName?: string;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
  onDelete: () => void;
  listName?: string;
  intl: any;
}

interface FavoriteListSettingsModalProps extends FavoriteListSettingsFormProps {
  modalClassName?: string;
  isOpen: boolean;
  onClose: () => void;
  manageDisableScrolling: any;
}

export const FavoriteListSettingsForm = ({
  rootClassName = '',
  onSubmit,
  onCancel = () => {},
  onDelete,
  listName = '',
  intl,
}: FavoriteListSettingsFormProps) => {
  return (
    <FinalForm
      rootClassName={rootClassName}
      onSubmit={onSubmit}
      render={(fieldRenderProps) => {
        const nameRequired = required(
          intl.formatMessage({ id: 'CreateListForm.nameRequiredMessage' })
        );
        const nameMaxLength = maxLength(
          intl.formatMessage(
            { id: 'CreateListForm.nameMaxLengthMessage'},
            { maxLength: FAVORITE_LIST_NAME_MAX_LENGTH }
          ),
          FAVORITE_LIST_NAME_MAX_LENGTH
        );

        return (
          <Form
            onSubmit={fieldRenderProps.handleSubmit}
            autoComplete="off"
          >
            <div className={css.header}>
              <Button type='button' className={css.deleteButton} onClick={onDelete}>
                <FormattedMessage id="FavoriteListSettingsForm.delete" />
              </Button>
              <h2 className={css.title}>
                <FormattedMessage id="FavoriteListSettingsForm.title" />
              </h2>
            </div>
            <div className={css.content}>
              <FieldTextInput
                id='list_name'
                name="listName"
                label={intl.formatMessage({ id: 'FavoriteListSettingsForm.inputLabel' })}
                placeholder={intl.formatMessage({ id: 'FavoriteListSettingsForm.inputPlaceholder' })}
                initialValue={listName}
                validate={composeValidators(nameRequired, nameMaxLength)}
              />
              <div className={css.footer}>
                <Button type='button' className={css.cancelButton} onClick={onCancel}>
                  <FormattedMessage id="FavoriteListSettingsForm.cancel" />
                </Button>
                <Button
                  className={css.saveButton}
                  disabled={fieldRenderProps.invalid}
                >
                  <FormattedMessage id="FavoriteListSettingsForm.save" />
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

const FavoriteListSettingsFormModal = ({
  modalClassName = '',
  isOpen,
  onClose,
  manageDisableScrolling,
  ...formProps
}: FavoriteListSettingsModalProps) => {
  return (
    <Modal
      className={modalClassName}
      containerClassName={css.container}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={manageDisableScrolling}
      id='favoriteListSettings'
    >
      <FavoriteListSettingsForm {...formProps} onCancel={onClose} />
    </Modal>
  );
}

export default FavoriteListSettingsFormModal;
