/* eslint-disable no-underscore-dangle */
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import rootReducer from './slices/reducer';

import * as analytics from '../analytics/analytics';
import { GoogleAnalyticsHandler, LoggingAnalyticsHandler } from 'analytics/handlers';

export default function configureAppStore(
  initialState = {},
  sdk?: SdkObject | null,
  analyticsHandlers?: (LoggingAnalyticsHandler | GoogleAnalyticsHandler)[]
) {
  const middleware = [thunk.withExtraArgument(sdk), analytics.createMiddleware(analyticsHandlers)];

  const store = configureStore({
    reducer: rootReducer(),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: middleware,
    enhancers: [],
  });

  return store;
}
