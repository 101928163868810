import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { actions as searchPageActions } from '../../storage/slices/searchPage/reducer';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import location1 from './images/location1.png';
import location2 from './images/location2.png';
import location3 from './images/location3.png';
import { useDispatch, useSelector } from 'react-redux';
import { searchNearestVacationCities } from '../../storage/slices/searchPage/actions';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery, key) => {
  return (
    <NamedLink key={key} name='SearchPage' to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id='SectionLocations.listingsInLocation'
          values={{ location: name }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const dispatch = useDispatch();
  const { rootClassName, className } = props;

  const {
    nearestLocations,
    isLocationPermissionDenied,
    nearestLocationsFetchError,
  } = useSelector((state) => state.searchPage);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(searchNearestVacationCities(`${position.coords.latitude},${position.coords.longitude}`));
      },
      (error) => {
        dispatch(searchPageActions.setIsLocationPermissionDenied(true));
      });
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const getLocationImage = index => {
    if (index === 0) {
      return location1;
    } else if (index === 1) {
      return location2;
    } else {
      return location3;
    };
  }

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id='SectionLocations.title' />
      </div>
      <div className={css.locations}>
        {isLocationPermissionDenied && (
          <div className={css.locationsErrorContainer}>
            <div className={css.locationsErrorMessage}>
              <FormattedMessage id="SectionLocations.locationsAccessError" />
            </div>
            <div className={css.locationsErrorMessageHint}>
              <FormattedMessage id="SectionLocations.locationsAccessHint" />
            </div>
          </div>
        )}
        {nearestLocationsFetchError && (
          <div className={css.locationsErrorMessage}>
            <FormattedMessage id="SectionLocations.locationsFetchError" />
          </div>
        )}
        {!isLocationPermissionDenied && !nearestLocationsFetchError && nearestLocations.map((location, index) => {
          const locationName = !location.place || location.place === location.city ? location.city : `${location.place}, ${location.city}`;
          return locationLink(
            locationName,
            getLocationImage(index),
            location.searchQuery,
            location.key,
          )
        })}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
