import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  IconCheckmark,
  Button,
} from '../../components';

import css from './AddFavoriteModal.module.css';
import { MAX_FAVORITE_LISTS } from '../../util/constants';
import emptyListImage from './images/NoImageSmall.png';

export interface AddFavoriteModalProps {
  isOpen?: boolean;
  manageDisableScrolling: any;
  onClose: () => void;
  onConfirm: (selectedLists: string[]) => void;
  onClickNewList: () => void;
  lists?: any[];
  listsImages?: any[];
  selectedListingId?: string;
}

export const AddFavoriteModal = ({
  isOpen = false,
  manageDisableScrolling,
  onClose,
  onConfirm,
  onClickNewList,
  lists = [],
  listsImages = [],
  selectedListingId = '',
}: AddFavoriteModalProps) => {
  const [selectedLists, setSelectedLists] = useState<string[]>([]);

  useEffect(() => {
    let newSelectedLists: string[] = [];
    lists?.forEach(list => {
      if (list.listings.includes(selectedListingId)) {
        newSelectedLists = [...newSelectedLists, list.id];
      }
    });
    setSelectedLists(newSelectedLists);
  }, [lists, selectedListingId]);

  const onClickList = (listId: string) => {
    if (selectedLists.includes(listId)) {
      setSelectedLists(prev => prev.filter(id => id !== listId));
    } else {
      setSelectedLists(prev => [...prev, listId]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={manageDisableScrolling}
      id='addFavorite'
      containerClassName={css.container}
    >
      <div className={css.header}>
        <h2 className={css.title}>
          <FormattedMessage id="AddFavoriteModal.title" />
        </h2>
      </div>
      <div className={css.content}>
        {lists.length < MAX_FAVORITE_LISTS && (
          <div
            className={css.listContainer}
            onClick={onClickNewList}
          >
            <div className={classNames(css.imageContainer, css.newListImageContainer)}>
              <svg className={css.newListIcon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 9H20V11H11V20H9V11H0V9H9V0H11V9Z"/>
              </svg>
            </div>
            <FormattedMessage id="AddFavoriteModal.createNew" />
          </div>
        )}
        {lists?.map(list => {
          const imageSrc = listsImages?.[list.id];

          return (
            <div
              key={list.id}
              className={css.listContainer}
              onClick={() => onClickList(list.id)}
            >
              <div className={css.imageContainer}>
                {imageSrc ? <img className={css.listImage} src={imageSrc} /> : <img className={css.emptyListImage} src={emptyListImage} />}
              </div>
              {list.name}
              <div className={css.iconContainer}>
                {selectedLists.includes(list.id) && (
                  <IconCheckmark className={css.iconSelected} size='small' />
                )}
              </div>
            </div>
          );
        })}
        <Button onClick={() => onConfirm(selectedLists)}>
          <FormattedMessage id='AddFavoriteModal.confirm' />
        </Button>
      </div>
    </Modal>
  );
};

export default AddFavoriteModal;
