import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';
import { SecondaryButton } from 'components/Button/Button';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const [isOpen, setIsOpen] = useState(true);

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
      </div>

      <div className={classNames(css.content, {[css.contentOpen]: isOpen})}>
        <div className={css.steps}>
          <div className={css.step}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part1Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionHowItWorks.part1Text" />
            </p>
          </div>

          <div className={css.step}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part2Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionHowItWorks.part2Text" />
            </p>
          </div>

          <div className={css.step}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part3Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionHowItWorks.part3Text" />
            </p>
          </div>
        </div>
      
        <div className={css.createListingLink}>
          <NamedLink name="NewListingPage">
            <FormattedMessage id="SectionHowItWorks.createListingLink" />
          </NamedLink>
        </div>
      </div>

      <SecondaryButton
        className={css.hideShowBtn}
        onClick={() => setIsOpen(prevState => !prevState)}
      >
        {isOpen ? (
          <FormattedMessage id="SectionHowItWorks.hideButtonExpanded" />
        ) : (
          <FormattedMessage id="SectionHowItWorks.hideButtonClosed" />
        )}
      </SecondaryButton>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
