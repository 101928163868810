import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  deleteAccountError: null,
  deleteAccountInProgress: false,
  accountDeleted: false,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const slice = createSlice({
  name: 'deleteAccountPage',
  initialState,
  reducers: {
    deleteAccountRequest: (state) => {
      Object.assign(state, {
        deleteAccountInProgress: true,
        deleteAccountError: null,
        accountDeleted: false,
      });
    },
    deleteAccountSuccess: (state) => {
      Object.assign(state, {
        deleteAccountInProgress: false,
        accountDeleted: true,
      });
    },
    deleteAccountError: (state, action) => {
      Object.assign(state, {
        deleteAccountInProgress: false,
        deleteAccountError: action.payload,
      });
    },
    deleteAccountClear: (state) => {
      Object.assign(state, {
        ...initialState,
      });
    },
    resetPasswordRequest: (state) => {
      Object.assign(state, {
        resetPasswordInProgress: true,
        resetPasswordError: null,
      });
    },
    resetPasswordSuccess: (state) => {
      Object.assign(state, {
        resetPasswordInProgress: false,
      });
    },
    resetPasswordError: (state, action) => {
      Object.assign(state, {
        resetPasswordInProgress: false,
        resetPasswordError: action.payload,
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
