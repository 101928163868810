import React from 'react';
import classNames from 'classnames';

import css from './IconHeart.module.css';

interface Props {
  rootClassName?: string;
  className?: string;
}

const IconHeart = ({
  rootClassName = '',
  className = ''
}: Props) => {
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9186 2.24669C11.81 1.81035 12.5876 1.66662 13.794 1.66664C16.8813 1.67942 19.1663 4.28316 19.1663 7.59986C19.1663 10.1314 17.7548 12.5769 15.1256 14.9415C13.7456 16.1827 11.9836 17.4111 10.7217 18.0645L9.99967 18.4384L9.27768 18.0645C8.01578 17.4111 6.25375 16.1827 4.87371 14.9415C2.24453 12.5769 0.833008 10.1314 0.833008 7.59986C0.833008 4.24768 3.0967 1.66663 6.2118 1.66663C7.37491 1.66663 8.19244 1.82394 9.10118 2.2734C9.41757 2.42989 9.71483 2.61551 9.99132 2.82976C10.2789 2.60279 10.5885 2.40823 10.9186 2.24669Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0114 13.7024C16.3218 11.6245 17.5 9.58329 17.5 7.59994C17.5 5.15751 15.9068 3.34215 13.7909 3.33338C12.8225 3.33337 12.2882 3.43212 11.6516 3.74373C11.26 3.9354 10.9126 4.19417 10.6095 4.523L10.0019 5.18229L9.38878 4.52818C9.0924 4.21202 8.75 3.95901 8.36261 3.76741C7.70677 3.44303 7.1369 3.33337 6.21212 3.33337C4.0697 3.33337 2.5 5.12314 2.5 7.59994C2.5 9.58329 3.67819 11.6245 5.98856 13.7024C7.24818 14.8353 8.86753 15.9675 10 16.5615C11.1325 15.9675 12.7518 14.8353 14.0114 13.7024Z" />
      <path d="M6.66699 2.5L10.0003 4.58333L11.667 2.91667H14.5837L17.5003 4.58333L17.917 7.5L17.5003 10.4167L15.8337 12.9167L13.3337 15.4167L10.0003 17.5L7.50033 15.4167L3.75033 12.5L2.08366 9.16667L1.66699 5.83333L3.75033 3.33333L6.66699 2.5Z" />
    </svg>
  );
};

export default IconHeart;
