import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl'
import classNames from 'classnames';

import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { metaTagProps } from '../../util/seo';
import { canonicalRoutePath } from '../../util/routes';
import { CookieConsent } from '..';

import facebookImage from '../../assets/umooziVacationFacebook-1200x630.jpg';
import twitterImage from '../../assets/umooziVacationTwitter-600x314.jpg';
import css from './Page.module.css';

const preventDefault = (e: DragEvent) => {
  e.preventDefault();
};

const twitterPageURL = (siteTwitterHandle: string) => {
  if (siteTwitterHandle && siteTwitterHandle.charAt(0) === '@') {
    return `https://twitter.com/${siteTwitterHandle.substring(1)}`;
  } else if (siteTwitterHandle) {
    return `https://twitter.com/${siteTwitterHandle}`;
  }
  return null;
};

interface PageComponentProps {
  className?: string;
  rootClassName?: string;
  children?: JSX.Element;
  scrollingDisabled?: boolean;
  referrer?: string;
  author?: string;
  contentType?: string;
  description?: string;
  facebookImages?: {
    width: number,
    height: number,
    url: string,
  }[];
  twitterImages?: {
    width: number,
    height: number,
    url: string,
  }[];
  published?: string;
  tags?: string;
  title: string;
  twitterHandle?: string;
  updated?: string;
  history: {
    listen: () => void,
  };
  location: any;
  schema?: any;
}

const PageComponent: React.FC<PageComponentProps & RouteComponentProps> = (props) => {
  const {
    className,
    rootClassName,
    children,
    location,
    scrollingDisabled,
    referrer,
    author,
    contentType,
    description,
    facebookImages,
    published,
    schema,
    tags,
    title,
    twitterHandle,
    twitterImages,
    updated,
  } = props;
  const intl = useIntl();

  let scrollPosition = 0;
  let contentDiv = null;
  let scrollingDisabledProps = scrollingDisabled;

  const scrollingDisabledChanged = (currentScrollingDisabled?: boolean) => {
    if (currentScrollingDisabled && currentScrollingDisabled !== scrollingDisabled) {
      // Update current scroll position, if scrolling is disabled (e.g. modal is open)
      scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      scrollingDisabledProps = currentScrollingDisabled;
    } else if (currentScrollingDisabled !== scrollingDisabled) {
      scrollingDisabledProps = currentScrollingDisabled;
    }
  }

  const classes = classNames(rootClassName || css.root, className, {
    [css.scrollingDisabled]: scrollingDisabledProps,
  });

  scrollingDisabledChanged(scrollingDisabledProps);
  const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

  const canonicalRootURL = config.canonicalRootURL;
  const shouldReturnPathOnly = !!referrer && referrer !== 'unsafe-url';
  const canonicalPath = canonicalRoutePath(routeConfiguration(), location, shouldReturnPathOnly);
  const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'Page.schemaDescription' });
  const metaTitle = title || schemaTitle;
  const metaDescription = description || schemaDescription;
  const facebookImgs = facebookImages || [
    {
      name: 'facebook',
      url: `${canonicalRootURL}${facebookImage}`,
      width: 1200,
      height: 630,
    },
  ];
  const twitterImgs = twitterImages || [
    {
      name: 'twitter',
      url: `${canonicalRootURL}${twitterImage}`,
      width: 600,
      height: 314,
    },
  ];

  const metaToHead = metaTagProps({
    author,
    contentType,
    description: metaDescription,
    facebookImages: facebookImgs,
    twitterImages: twitterImgs,
    published,
    tags,
    title: metaTitle,
    twitterHandle,
    updated,
    url: canonicalUrl,
    locale: intl.locale,
  });

  // eslint-disable-next-line react/no-array-index-key
  const metaTags = metaToHead.map((metaProps, i) => <meta key={i} {...metaProps} />);

  const facebookPage = config.siteFacebookPage;
  const twitterPage = twitterPageURL(config.siteTwitterHandle);
  const instagramPage = config.siteInstagramPage;
  const sameOrganizationAs = [facebookPage, twitterPage, instagramPage].filter(v => v != null);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format

  // Schema attribute can be either single schema object or an array of objects
  // This makes it possible to include several different items from the same page.
  // E.g. Product, Place, Video
  const schemaFromProps = Array.isArray(schema) ? schema : [schema];
  const schemaArrayJSONString = JSON.stringify([
    ...schemaFromProps,
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': `${canonicalRootURL}#organization`,
      url: canonicalRootURL,
      name: siteTitle,
      sameAs: sameOrganizationAs,
      logo: `${canonicalRootURL}/static/webapp-icon-192x192.png`,
      address: config.address,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: canonicalRootURL,
      description: schemaDescription,
      name: schemaTitle,
      publisher: {
        '@id': `${canonicalRootURL}#organization`,
      },
    },
  ]);

  const scrollPositionStyles = scrollingDisabledProps
    ? { marginTop: `${-1 * scrollPosition}px` }
    : {};

  // If scrolling is not disabled, but content element has still scrollPosition set
  // in style attribute, we scrollTo scrollPosition.
  const hasMarginTopStyle = contentDiv && (contentDiv as any).style.marginTop;
  if (!scrollingDisabledProps && hasMarginTopStyle) {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollPosition);
    });
  }

  React.useEffect(() => {
    // By default a dropped file is loaded in the browser window as a
    // file URL. We want to prevent this since it might loose a lot of
    // data the user has typed but not yet saved. Preventing requires
    // handling both dragover and drop events.
    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', preventDefault);

    // Remove duplicated server-side rendered page schema.
    // It's in <body> to improve initial rendering performance,
    // but after web app is initialized, react-helmet-async operates with <head>
    const pageSchema = document.getElementById('page-schema');
    if (pageSchema) {
      pageSchema.remove();
    }
    return () => {
      document.removeEventListener('dragover', preventDefault);
      return document.removeEventListener('drop', preventDefault);
    }
  }, []);

  return (
    <div className={classes}>
      <Helmet
        htmlAttributes={{
          lang: intl.locale,
        }}
      >
        <title>{title}</title>
        {referrerMeta}
        <link rel="canonical" href={canonicalUrl} />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="Content-Language" content={intl.locale} />
        {metaTags}
        <script id="page-schema" type="application/ld+json">
          {schemaArrayJSONString.replace(/</g, '\\u003c')}
        </script>
      </Helmet>
      <CookieConsent />
      <div
        className={css.content}
        style={scrollPositionStyles}
        ref={c => {
          contentDiv = c;
        }}
      >
        {children}
      </div>
    </div>
  );
}

const Page = withRouter(PageComponent);
Page.displayName = 'Page';

export default Page;
