import React from 'react';
import classNames from 'classnames';

import css from './ContactUsIcons.module.css';

interface Props {
  rootClassName?: string;
  className?: string;
}

const IconLocation = ({
  rootClassName = '',
  className = ''
}: Props) => {
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Location</title>
      <path d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
      <circle cx="256" cy="192" r="48" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
    </svg>
  );
};

export default IconLocation;
