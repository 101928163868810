import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  return (
    <FinalForm
      {...props}
      validateOnBlur
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordRepeatLabel = intl.formatMessage({
          id: 'SignupForm.passwordRepeatLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRepeatPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordRepeatPlaceholder',
        });
        const passwordMatchMessage = intl.formatMessage({
          id: 'SignupForm.passwordMatch',
        });
        const passwordInvalidMessage = intl.formatMessage({
          id: 'SignupForm.passwordInvalid',
        })
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordValid = validators.passwordFormatValid(passwordInvalidMessage);
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const password = fieldRenderProps.values.password;
        const passwordRepeat = fieldRenderProps.values.passwordRepeat;

        const passwordMatch = validators.passwordMatch(
          passwordMatchMessage,
          passwordRepeat,
        );

        const passwordRepeatMatch = validators.passwordRepeatMatch(
          passwordMatchMessage,
          password,
        );

        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength,
          passwordValid,
        );

        const passwordRepeatValidators = validators.composeValidators(
          passwordRequired,
          passwordRepeatMatch,
        );
        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !isTermsAccepted;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                className={css.signupFormInput}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={classNames(css.signupFormInput, css.firstNameRoot)}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={classNames(css.signupFormInput, css.lastNameRoot)}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
                <FieldTextInput
                  className={classNames(css.signupFormInput, css.password)}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                  showPasswordControl
                />
              <FieldTextInput
                className={classNames(css.signupFormInput, css.password)}
                type="password"
                id={formId ? `${formId}.passwordRepeat` : 'passwordRepeat'}
                name="passwordRepeat"
                autoComplete="new-password-repeat"
                label={passwordRepeatLabel}
                placeholder={passwordRepeatPlaceholder}
                validate={passwordRepeatValidators}
                showPasswordControl
              />
            </div>
            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <Field name='termsAndConditionsAccept'>
                  {({ input }) => (
                    <label className={css.discountFieldRadioButton}>
                      <input
                        {...input}
                        type="checkbox"
                        className={css.discountFieldRadioInput}
                        defaultChecked={false}
                        onChange={event => {
                          input.onChange(event);
                          setIsTermsAccepted(prevState => !prevState);
                        }}
                      />
                      <div className={css.discountFieldRadioBox} />
                      <span className={css.termsText}>
                        <FormattedMessage
                          id="SignupForm.termsAndConditionsAcceptText"
                          values={{ termsLink }}
                        />
                      </span>
                    </label>
                  )}
                </Field>
              </p>
              <PrimaryButton
                className={css.bottomWrapperButton}
                type="submit" inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  )
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
