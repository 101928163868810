import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  setupIntentInProgress: false,
  setupIntentError: null,
  setupIntent: null,
  stripeCustomerFetched: false,
};

const slice = createSlice({
  name: 'paymentMethodsPage',
  initialState,
  reducers: {
    setupIntentRequest: (state) => {
      Object.assign(state, {
        setupIntentInProgress: true, 
        setupIntentError: null,
      });
    },
    setupIntentSuccess: (state, action) => {
      Object.assign(state, {
        setupIntentInProgress: false,
        setupIntentError: null,
        setupIntent: action.payload,
      });
    },
    setupIntentError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
      Object.assign(state, {
        setupIntentInProgress: false, 
        setupIntentError: null,
      });
    },
    stripeCustomerRequest: (state) => {
      state.stripeCustomerFetched = false;
    },
    stripeCustomerSuccess: (state) => {
      state.stripeCustomerFetched = true;
    },
    stripeCustomerError: (state, action) => {
      console.error(action.payload); // eslint-disable-line no-console
     
      state.stripeCustomerFetchError = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
