import React, { useState } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import TopbarNewSearchForm from '../../forms/TopbarNewSearchForm/TopbarNewSearchForm';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { validFilterParams } from '../../containers/SearchPage/SearchPage.helpers';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const {
    rootClassName,
    className,
    currentSearchParams,
    filterConfig,
    history,
    intl
  } = props;
  const [formattedForSearchDate, setFormattedForSearchDate] = useState("");
  const classes = classNames(rootClassName || css.root, className);


  const handleSubmit = (values) => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;

    const { address,  ...rest } = parse(search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
      dates: formattedForSearchDate,
      ...validFilterParams(rest, filterConfig),
    };

   history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  const handleDate = (formattedDate) => {
    setFormattedForSearchDate(formattedDate)
  }

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <TopbarNewSearchForm
          handleDate={handleDate}
          onSubmit={handleSubmit}
          intl={intl}
          rootClassName={rootClassName}
        />
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <NamedLink
          name="SearchPage"
          className={css.heroButton}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
  filterConfig: config.custom.filters
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired,
};

export default SectionHero;
