import { actions } from './reducer';
import { storableError } from '../../../util/errors';

export const recoverPassword = email => (dispatch, getState, sdk) => {
  dispatch(actions.passwordRecoveryRequest());

  return sdk.passwordReset
    .request({ email })
    .then(() => dispatch(actions.passwordRecoverySuccess(email)))
    .catch(e => dispatch(actions.passwordRecoveryError({
      error: storableError(e),
      email: email,
    })));
};
