/**
 * Export loadData calls from ducks modules of different containers
 */
import { actions as checkoutPageActions} from 'storage/slices/checkoutPage';
import { loadData as ContactDetailsPageLoader } from 'storage/slices/contactDetailsPage/actions';
import { loadData as EditListingPageLoader } from 'storage/slices/editListingPage/actions';
import { loadData as EmailVerificationPageLoader } from 'storage/slices/emailVerification';
import { loadData as InboxPageLoader } from 'storage/slices/inboxPage/actions';
import { loadData as BookingsPageLoader } from 'storage/slices/manageBookingsPage/actions';
import { loadDataForFavorites as FavoriteBookingsPageLoader } from 'storage/slices/manageBookingsPage/actions';
import { loadDataForFavoritesList as FavoriteListPageLoader } from 'storage/slices/manageBookingsPage/actions';
import { loadData as ListingPageLoader } from 'storage/slices/listingPage/actions';
import { loadData as ManageListingsPageLoader } from 'storage/slices/manageListingsPage/actions';
import { loadData as PaymentMethodsPageLoader } from 'storage/slices/paymentsMethodsPage/actions';
import { loadData as ProfilePageLoader } from 'storage/slices/profilePage/actions';
import { loadData as SearchPageLoader } from 'storage/slices/searchPage/actions';
import { loadData as StripePayoutPageLoader } from 'storage/slices/stripePayoutPage/actions';
import { actions as TransactionPage } from 'storage/slices/transactionPage';

const getPageDataLoadingAPI = () => {
  return {
    CheckoutPage: {
      setInitialValues: checkoutPageActions.setInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    PendingConfirmationInboxPage: {
      loadData: InboxPageLoader,
    },
    ConfirmedReservationInboxPage: {
      loadData: InboxPageLoader,
    },
    DeclinedInboxPage: {
      loadData: InboxPageLoader,
    },
    CompletedInboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageBookingsPage: {
      loadData: BookingsPageLoader,
    },
    PendingConfirmationPage: {
      loadData: BookingsPageLoader,
    },
    UpcomingBookingPage: {
      loadData: BookingsPageLoader,
    },
    FavoriteBookingsPage: {
      loadData: FavoriteBookingsPageLoader,
    },
    FavoriteListPage: {
      loadData: FavoriteListPageLoader,
    },
    PastBookingPage: {
      loadData: BookingsPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPage.loadData,
      setInitialValues: TransactionPage.setInitialValues,
    },
  };
};

export default getPageDataLoadingAPI;
