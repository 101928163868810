import React from 'react';
import { Form as FinalForm, FormProps } from 'react-final-form';

import { FormattedMessage } from '../../util/reactIntl';
import {
  FAVORITE_LIST_NAME_MAX_LENGTH,
  required,
  maxLength,
  composeValidators,
} from '../../util/validators';
import {
  Button,
  FieldTextInput,
  Form,
  Modal,
} from '../../components';

import css from './CreateListForm.module.css';

interface CreateListFormProps extends FormProps {
  rootClassName?: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isCleanOnSubmit?: boolean;
  intl: any;
}

interface CreateListModalProps extends CreateListFormProps {
  modalClassName: string;
  isOpen: boolean;
  onClose: () => void;
  manageDisableScrolling: any;
}

export const CreateListForm = ({
  rootClassName = '',
  onSubmit,
  onCancel,
  isCleanOnSubmit = false,
  intl,
}: CreateListFormProps) => {
  return (
    <FinalForm
      rootClassName={rootClassName}
      onSubmit={onSubmit}
      render={(fieldRenderProps) => {
        const nameRequired = required(
          intl.formatMessage({ id: 'CreateListForm.nameRequiredMessage' })
        );
        const nameMaxLength = maxLength(
          intl.formatMessage(
            { id: 'CreateListForm.nameMaxLengthMessage'},
            { maxLength: FAVORITE_LIST_NAME_MAX_LENGTH }
          ),
          FAVORITE_LIST_NAME_MAX_LENGTH
        );

        return (
          <Form
            onSubmit={values => {
              fieldRenderProps.handleSubmit(values);
              isCleanOnSubmit && fieldRenderProps.form.restart();
            }}
            autoComplete="off"
          >
            <div className={css.header}>
              <h2 className={css.title}>
                <FormattedMessage id="CreateListForm.title" />
              </h2>
            </div>
            <div className={css.content}>
              <FieldTextInput
                id='list_name'
                name="listName"
                label={intl.formatMessage({ id: 'CreateListForm.inputLabel' })}
                placeholder={intl.formatMessage({ id: 'CreateListForm.inputPlaceholder' })}
                validate={composeValidators(nameRequired, nameMaxLength)}
              />
              <div className={css.footer}>
                <Button type='button' className={css.cancelButton} onClick={onCancel}>
                  <FormattedMessage id="CreateListForm.cancel" />
                </Button>
                <Button
                  className={css.saveButton}
                  disabled={fieldRenderProps.invalid}
                >
                  <FormattedMessage id="CreateListForm.save" />
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

const CreateListFormModal = ({
  modalClassName,
  isOpen = false,
  onClose = () => {},
  manageDisableScrolling,
  ...formProps
}: CreateListModalProps) => {
  return (
    <Modal
      className={modalClassName}
      containerClassName={css.container}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={manageDisableScrolling}
      id='createList'
    >
      <CreateListForm {...formProps} onCancel={onClose} />
    </Modal>
  );
}

export default CreateListFormModal;
