import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  favorites: [],
};

const slice = createSlice({
  name: 'profileSettingsPage',
  initialState,
  reducers: {
    clearUpdatedForm: (state) => {
      Object.assign(state, {
        updateProfileError: null,
        uploadImageError: null,
      });
    },
    uploadImageRequest: (state, action) => {
      // payload.params: { id: 'tempId', file }
      Object.assign(state, {
        image: { ...action.payload },
        uploadInProgress: true,
        uploadImageError: null,
      });
    },
    uploadImageSuccess: (state, action) => {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = action.payload.data;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };

      Object.assign(state, {
        image,
        uploadInProgress: false,
      });
    },
    uploadImageError: (state, action) => {
      const { payload } = action;
      console.error(payload);// eslint-disable-next-line no-console
      Object.assign(state, {
        image: null,
        uploadInProgress: false,
        uploadImageError: payload.error,
      });
    },
    updateProfileRequest: (state) => {
      Object.assign(state, {
        updateInProgress: true,
        updateProfileError: null,
      });
    },
    updateProfileSuccess: (state) => {
      Object.assign(state, {
        image: null,
        updateInProgress: false,
      });
    },
    updateProfileError: (state, action) => {
      Object.assign(state, {
        image: null,
        updateInProgress: false,
        updateProfileError: action.payload,
      });
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
